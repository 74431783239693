import { ErrorBoundary as SentryErrorBoundary } from '@sentry/react';
import { ReactNode } from 'react';
import { useLocation } from 'react-router-dom';
import { ErrorCard } from './ErrorCard';
import { interpretError } from './errors';

interface Props {
  children?: ReactNode;
  isRoot?: boolean;
}
export function ErrorBoundary(props: Props) {
  const { pathname } = useLocation(); // using the pathname as key so the boundary resets when navigating
  return (
    <SentryErrorBoundary
      key={pathname}
      {...props}
      onError={(error) => {
        const { shouldBeHandledAtRoot } = interpretError(error);
        if (shouldBeHandledAtRoot && !props.isRoot) {
          throw error;
        }
      }}
      fallback={({ error, eventId }) => (
        <ErrorCard {...interpretError(error)} eventId={eventId} isRoot={props.isRoot} />
      )}
    />
  );
}

import Lottie from 'react-lottie-player/dist/LottiePlayerLight';
import animationData from './SmcSpinner.json';

interface LungSpinnerProps {
  size?: 'md' | 'lg';
}

export function SmcSpinner({ size = 'md' }: LungSpinnerProps) {
  return <Lottie play loop animationData={animationData} style={{ width: size === 'lg' ? 128 : 64 }} />;
}

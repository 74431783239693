import { Button, Icons } from '@healthinal/ui';
import { AxiosError, isAxiosError } from 'axios';
import { ReactNode } from 'react';

export interface ErrorPresentation {
  title?: string;
  description: string;
  details?: string;
  actions?: ReactNode;
  shouldBeReported: boolean;
  shouldBeHandledAtRoot?: boolean;
}

export const interpretError = (error: unknown): ErrorPresentation => {
  if (isAxiosError(error)) {
    if (error.code === AxiosError.ERR_NETWORK) {
      return {
        shouldBeReported: false,
        title: 'Netzwerkfehler',
        description:
          'Es ist ein Fehler in der Verbindung zum Server aufgetreten. Bitte überprüfen Sie Ihre Verbindung zum Internet.',
      };
    }
    if (error.response?.status === 401) {
      return {
        shouldBeReported: false,
        shouldBeHandledAtRoot: true,
        title: 'Login ungültig',
        description: 'Ihr Login ist nicht gültig. Bitte melden Sie sich ab',
      };
    }
    if (error.response?.status === 403) {
      return {
        shouldBeReported: false,
        title: 'Zugriff verweigert',
        description: 'Sie haben keine Berechtigung für diese Aktion',
        actions: (
          <Button fullWidth component={'a'} href={'/?logout'} endDecorator={<Icons.Logout />}>
            Abmelden
          </Button>
        ),
      };
    }
    if (error.response?.status === 404) {
      const expectedUrls = [/\/api\/heureka\/practices\/[^/]+\/patients\?pid=.*/];
      const shouldBeReported = shouldBeReportedAtUrl(expectedUrls, error);

      return {
        shouldBeReported: shouldBeReported,
        title: 'Nicht gefunden',
        description: 'Die angeforderte Ressource konnte nicht gefunden werden.',
      };
    }
    if (error.response?.status === 410) {
      return {
        shouldBeReported: false,
        title: 'Ressource nicht mehr verfügbar',
        description: 'Die angeforderte Ressource ist nicht mehr verfügbar.',
      };
    }
    if (error.response?.status === 409) {
      const expectedUrls = [/\/api\/patients\/[^/]+\/linkheureka/];
      const shouldBeReported = shouldBeReportedAtUrl(expectedUrls, error);
      return {
        shouldBeReported: shouldBeReported,
        title: 'Konflikt aufgetreten',
        description:
          'Die angeforderte Aktion konnte nicht ausgeführt werden, da ein Konflikt mit den aktuellen Daten besteht.',
      };
    }
  }
  return {
    shouldBeReported: true,
    title: 'Unbekannter Fehler',
    description: 'Ein unbekannter Fehler ist aufgetreten',
    details: error?.toString(),
  };
};

function shouldBeReportedAtUrl(expectedUrls: RegExp[], error: AxiosError) {
  const url = error.response?.config.url;
  if (url) {
    return !expectedUrls.some((regex) => regex.test(url));
  }
  return true;
}

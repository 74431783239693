import { CssBaseline, CssVarsProvider, StyledEngineProvider } from '@healthinal/ui';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Provider as JotaiProvider, WritableAtom } from 'jotai';
import { queryClientAtom } from 'jotai-tanstack-query';
import { useHydrateAtoms } from 'jotai/react/utils';
import { ReactNode, StrictMode } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { queryClient } from './api/queryClient';
import { theme } from './theme';

export default function Providers({ children }: { children: ReactNode }) {
  return (
    <StrictMode>
      <StyledEngineProvider injectFirst>
        <CssVarsProvider theme={theme}>
          <CssBaseline />
          <BrowserRouter>
            <QueryClientProvider client={queryClient}>
              <JotaiProvider>
                <HydrateAtoms initialValues={[[queryClientAtom, queryClient]]}>{children}</HydrateAtoms>
              </JotaiProvider>
              <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
          </BrowserRouter>
        </CssVarsProvider>
      </StyledEngineProvider>
    </StrictMode>
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AnyWritableAtom = WritableAtom<unknown, any[], any>;
type AtomTuple<A = AnyWritableAtom, V = unknown> = readonly [A, V];

const HydrateAtoms = ({ initialValues, children }: { initialValues: Array<AtomTuple>; children: ReactNode }) => {
  useHydrateAtoms(initialValues);
  return <>{children}</>;
};

import axios, { AxiosRequestConfig } from 'axios';

const axiosInstance = axios.create({ baseURL: '/api' });

// add a second `options` argument here if you want to pass extra options to each generated query
export const request = async <T>(config: AxiosRequestConfig, options?: AxiosRequestConfig): Promise<T> => {
  const response = await axiosInstance.request<T>({ ...config, ...options });
  return response.data;
};

axiosInstance.interceptors.response.use((response) => {
  // An HTML response indicates that the HIN session has expired (status code 200)
  const contentType: unknown = response.headers['content-type'];
  if (typeof contentType === 'string' && contentType.startsWith('text/html')) {
    window.location.reload(); // Reload to show HIN login screen
  }

  return response;
});

axiosInstance.interceptors.request.use((request) => {
  request.headers['SMC-CSRF-PROTECTION'] = '1';

  return request;
});

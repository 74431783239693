/**
 * Generated by orval v7.6.0 🍺
 * Do not edit manually.
 * Smart Managed Care API
 * OpenAPI spec version: v0
 */
import type {
  DataTag,
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query';
import { useMutation, useQuery, useSuspenseQuery } from '@tanstack/react-query';

import { request } from './axios';
export interface NotificationSelectionDto {
  deputyNotification: boolean;
  callcenterNotification: boolean;
  partnerNotification: boolean;
}

export interface HospitalSelectionForPracticeUpdateDto {
  hospitalId: string;
  isSelected: boolean;
}

export interface LinkHeurekaDto {
  pid: string;
  heurekaId: string;
}

export interface CreateDeputyDto {
  deputyPracticeId: string;
  sharedPracticeId: string;
  deputyDurationDays: number;
}

export interface RejectDeputyDto {
  message: string;
}

export type PracticeDtoIsPartner = boolean | null;

export type PracticeDtoStreet = string | null;

export type PracticeDtoPostcode = string | null;

export type PracticeDtoCity = string | null;

export type PracticeDtoIsPatientMatchRunnerEnabled = boolean | null;

export interface PracticeDto {
  id: string;
  name: string;
  email: string;
  isConnectedToHeureka: boolean;
  isPartner?: PracticeDtoIsPartner;
  street?: PracticeDtoStreet;
  postcode?: PracticeDtoPostcode;
  city?: PracticeDtoCity;
  isPatientMatchRunnerEnabled?: PracticeDtoIsPatientMatchRunnerEnabled;
  isHospitalAccessPermitted: boolean;
}

export type UserDtoHinId = string | null;

export type UserDtoFirstName = string | null;

export type UserDtoLastName = string | null;

export type UserDtoLoginType = (typeof UserDtoLoginType)[keyof typeof UserDtoLoginType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserDtoLoginType = {
  PRACTICE: 'PRACTICE',
  PERSONAL: 'PERSONAL',
} as const;

export interface UserDto {
  id: string;
  hinId?: UserDtoHinId;
  email: string;
  firstName?: UserDtoFirstName;
  lastName?: UserDtoLastName;
  loginType: UserDtoLoginType;
}

export interface WhoamiDto {
  user: UserDto;
  practices: PracticeDto[];
}

export type SharedPatientDtoBirthDate = string | null;

export type SharedPatientDtoAddress = string | null;

export type SharedPatientDtoZipCode = string | null;

export type SharedPatientDtoCity = string | null;

export type SharedPatientDtoInsurancePatientNumber = string | null;

export type SharedPatientDtoInsuranceName = string | null;

export type SharedPatientDtoPid = string | null;

export type SharedPatientDtoShareType = (typeof SharedPatientDtoShareType)[keyof typeof SharedPatientDtoShareType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SharedPatientDtoShareType = {
  PARTNER: 'PARTNER',
  DEPUTY: 'DEPUTY',
  EMERGENCY_SERVICE: 'EMERGENCY_SERVICE',
} as const;

export interface SharedPatientDto {
  id: string;
  firstName: string;
  lastName: string;
  birthDate?: SharedPatientDtoBirthDate;
  address?: SharedPatientDtoAddress;
  zipCode?: SharedPatientDtoZipCode;
  city?: SharedPatientDtoCity;
  insurancePatientNumber?: SharedPatientDtoInsurancePatientNumber;
  insuranceName?: SharedPatientDtoInsuranceName;
  pid?: SharedPatientDtoPid;
  practiceName: string;
  shareType: SharedPatientDtoShareType;
}

export type HospitalCreated = string | null;

export type HospitalUpdated = string | null;

export interface Hospital {
  id: string;
  name: string;
  emergencyEmail: string;
  created?: HospitalCreated;
  updated?: HospitalUpdated;
}

export interface HospitalSelectionForPracticeDto {
  hospital: Hospital;
  isSelected: boolean;
}

export type PatientDtoPracticeId = string | null;

export type PatientDtoExternalId = string | null;

export type PatientDtoBirthDate = string | null;

export type PatientDtoAddress = string | null;

export type PatientDtoZipCode = string | null;

export type PatientDtoCity = string | null;

export type PatientDtoInsurancePatientNumber = string | null;

export type PatientDtoManagedByZsr = string | null;

export type PatientDtoHeurekaPatientId = string | null;

export type PatientDtoInsuranceName = string | null;

export type PatientDtoPid = string | null;

export type PatientDtoStatus = (typeof PatientDtoStatus)[keyof typeof PatientDtoStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PatientDtoStatus = {
  NOT_SYNCED: 'NOT_SYNCED',
  SYNCING: 'SYNCING',
  NOT_FOUND: 'NOT_FOUND',
  EXTERNAL_ACCESS_SOON: 'EXTERNAL_ACCESS_SOON',
  EXTERNAL_ACCESS_GRANTED: 'EXTERNAL_ACCESS_GRANTED',
  EXTERNAL_ACCESS_REVOKED: 'EXTERNAL_ACCESS_REVOKED',
  NEVER_SEEN: 'NEVER_SEEN',
} as const;

export type PatientDtoHeurekaPatientMatchStatus =
  | 'NOT_FOUND'
  | 'MATCH_PENDING'
  | 'PERSONAL_INFO_MATCHED'
  | 'AHV_NUMBER_MATCHED'
  | 'MANUALLY_MATCHED'
  | null;

export interface PatientDto {
  id: string;
  practiceId?: PatientDtoPracticeId;
  externalId?: PatientDtoExternalId;
  firstName: string;
  lastName: string;
  birthDate?: PatientDtoBirthDate;
  address?: PatientDtoAddress;
  zipCode?: PatientDtoZipCode;
  city?: PatientDtoCity;
  insurancePatientNumber?: PatientDtoInsurancePatientNumber;
  managedByZsr?: PatientDtoManagedByZsr;
  heurekaPatientId?: PatientDtoHeurekaPatientId;
  insuranceName?: PatientDtoInsuranceName;
  pid?: PatientDtoPid;
  externallyAvailableInDays: number;
  status: PatientDtoStatus;
  heurekaPatientMatchStatus?: PatientDtoHeurekaPatientMatchStatus;
}

export type HeurekaPatientDtoPid = string | null;

export interface HeurekaPatientDto {
  pid?: HeurekaPatientDtoPid;
  heurekaId: string;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
}

export interface HeurekaDto {
  isConnected: boolean;
}

export type HeurekaMedicationStatementDtoComment = string | null;

export type HeurekaMedicationStatementDtoFrom = string | null;

export type HeurekaMedicationStatementDtoUntil = string | null;

export type HeurekaMedicationStatementDtoDosages = { [key: string]: string };

export type HeurekaMedicationStatementDtoPatientId = string | null;

export interface HeurekaMedicationStatementDto {
  medicationName: string;
  comment?: HeurekaMedicationStatementDtoComment;
  from?: HeurekaMedicationStatementDtoFrom;
  until?: HeurekaMedicationStatementDtoUntil;
  dosages: HeurekaMedicationStatementDtoDosages;
  asNeeded: boolean;
  patientId?: HeurekaMedicationStatementDtoPatientId;
}

export interface HeurekaPatientDetailsDto {
  patient: HeurekaPatientDto;
  medicationStatements: HeurekaMedicationStatementDto[];
}

export interface EmergencyCircleDto {
  id: string;
  name: string;
  practices: PracticeDto[];
}

export interface EmergencyServiceDto {
  id: string;
  emergencyCircle: EmergencyCircleDto;
  practiceOnDuty: PracticeDto;
  endDateTime: string;
}

export type DeputyDtoStatus = (typeof DeputyDtoStatus)[keyof typeof DeputyDtoStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DeputyDtoStatus = {
  OFFERED: 'OFFERED',
  ACCEPTED: 'ACCEPTED',
  REJECTED: 'REJECTED',
  REVOKED: 'REVOKED',
} as const;

export type DeputyDtoCreated = string | null;

export type DeputyDtoUpdated = string | null;

export interface DeputyDto {
  id: string;
  offeringUser: UserDto;
  deputyPractice: PracticeDto;
  sharedPractice: PracticeDto;
  endDate: string;
  status: DeputyDtoStatus;
  created?: DeputyDtoCreated;
  updated?: DeputyDtoUpdated;
}

export type AccessLogDtoCreated = string | null;

export type AccessLogDtoUser = string | null;

export type AccessLogDtoUserType = (typeof AccessLogDtoUserType)[keyof typeof AccessLogDtoUserType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AccessLogDtoUserType = {
  CALLCENTER: 'CALLCENTER',
  PRACTICE: 'PRACTICE',
  DEPUTY: 'DEPUTY',
  EMERGENCY_SERVICE: 'EMERGENCY_SERVICE',
  PARTNER: 'PARTNER',
  HOSPITAL: 'HOSPITAL',
} as const;

export type AccessLogDtoPid = string | null;

export interface AccessLogDto {
  id: string;
  created?: AccessLogDtoCreated;
  user?: AccessLogDtoUser;
  userType: AccessLogDtoUserType;
  patientLastName: string;
  patientFirstName: string;
  patientBirthDate: string;
  pid?: AccessLogDtoPid;
  count: number;
}

export type SetExternalAccessRevokedByPracticeParams = {
  practiceId: string;
};

export type MakeExternallyAccessibleNowByPracticeParams = {
  practiceId: string;
};

export type SetExternalAccessGrantedByPracticeParams = {
  practiceId: string;
};

export type CreatePatientDataRequestBody = {
  ahv: string;
  birthDate: string;
  pid: string;
  fid: string;
  hospitalName: string;
  department?: string;
  confirmationEmail: string;
  requestId: string;
  created: string;
  hmac: string;
};

export type StartEmergencyServiceParams = {
  emergencyCircleId: string;
  dutyPracticeId: string;
};

export type SharePatientWithPartnerParams = {
  practiceId: string;
  expiration: string;
};

export type GetPatientsSharedWithPracticeParams = {
  practiceId: string;
};

export type GetPatientsByPracticeParams = {
  practiceId: string;
};

export type DenySharePatientWithHospitalParams = {
  hospitalPatientRequestId: string;
  hmac: string;
};

export type DenySharePatientWithHospital200 =
  (typeof DenySharePatientWithHospital200)[keyof typeof DenySharePatientWithHospital200];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DenySharePatientWithHospital200 = {
  SUCCESS: 'SUCCESS',
  DENIED: 'DENIED',
  ERROR: 'ERROR',
} as const;

export type AcceptSharePatientWithHospitalParams = {
  hospitalPatientRequestId: string;
  hmac: string;
};

export type AcceptSharePatientWithHospital200 =
  (typeof AcceptSharePatientWithHospital200)[keyof typeof AcceptSharePatientWithHospital200];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AcceptSharePatientWithHospital200 = {
  SUCCESS: 'SUCCESS',
  DENIED: 'DENIED',
  ERROR: 'ERROR',
} as const;

export type GetHeurekaPatientByPidParams = {
  pid: string;
};

export type GetActiveEmergencyServiceForEmergencyCircleParams = {
  emergencyCircleId: string;
};

export type GetEmergencyCirclesForPracticeParams = {
  practiceId: string;
};

export type GetUnexpiredNonRevokedDeputiesForSharedPracticeParams = {
  sharedPracticeId: string;
};

export type GetDeputyPracticesByNameParams = {
  nameQuery: string;
  practiceId: string;
};

export type GetActiveDeputiesForDeputyPracticeParams = {
  deputyPracticeId: string;
};

export type IsPatientDataAvailableParams = {
  insurance: string;
  insuranceCustomerNr: string;
  requestId: string;
  user: string;
  created: string;
  hmac: string;
};

export type GetPartnerPracticesParams = {
  patientId?: string;
};

export type GetEmailAddressParams = {
  zsr: string;
  requestId: string;
  user: string;
  created: string;
  hmac: string;
};

export type GetAccessLogsForPracticeParams = {
  practiceId: string;
};

type SecondParameter<T extends (...args: never) => unknown> = Parameters<T>[1];

export const getNotificationSelection = (options?: SecondParameter<typeof request>, signal?: AbortSignal) => {
  return request<NotificationSelectionDto>({ url: `/user/notification`, method: 'GET', signal }, options);
};

export const getGetNotificationSelectionQueryKey = () => {
  return [`/user/notification`] as const;
};

export const getGetNotificationSelectionQueryOptions = <
  TData = Awaited<ReturnType<typeof getNotificationSelection>>,
  TError = unknown,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getNotificationSelection>>, TError, TData>>;
  request?: SecondParameter<typeof request>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetNotificationSelectionQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getNotificationSelection>>> = ({ signal }) =>
    getNotificationSelection(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getNotificationSelection>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetNotificationSelectionQueryResult = NonNullable<Awaited<ReturnType<typeof getNotificationSelection>>>;
export type GetNotificationSelectionQueryError = unknown;

export function useGetNotificationSelection<
  TData = Awaited<ReturnType<typeof getNotificationSelection>>,
  TError = unknown,
>(options: {
  query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getNotificationSelection>>, TError, TData>> &
    Pick<
      DefinedInitialDataOptions<
        Awaited<ReturnType<typeof getNotificationSelection>>,
        TError,
        Awaited<ReturnType<typeof getNotificationSelection>>
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof request>;
}): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetNotificationSelection<
  TData = Awaited<ReturnType<typeof getNotificationSelection>>,
  TError = unknown,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getNotificationSelection>>, TError, TData>> &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<ReturnType<typeof getNotificationSelection>>,
        TError,
        Awaited<ReturnType<typeof getNotificationSelection>>
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof request>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetNotificationSelection<
  TData = Awaited<ReturnType<typeof getNotificationSelection>>,
  TError = unknown,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getNotificationSelection>>, TError, TData>>;
  request?: SecondParameter<typeof request>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useGetNotificationSelection<
  TData = Awaited<ReturnType<typeof getNotificationSelection>>,
  TError = unknown,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getNotificationSelection>>, TError, TData>>;
  request?: SecondParameter<typeof request>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetNotificationSelectionQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getGetNotificationSelectionSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getNotificationSelection>>,
  TError = unknown,
>(options?: {
  query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getNotificationSelection>>, TError, TData>>;
  request?: SecondParameter<typeof request>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetNotificationSelectionQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getNotificationSelection>>> = ({ signal }) =>
    getNotificationSelection(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getNotificationSelection>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetNotificationSelectionSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof getNotificationSelection>>
>;
export type GetNotificationSelectionSuspenseQueryError = unknown;

export function useGetNotificationSelectionSuspense<
  TData = Awaited<ReturnType<typeof getNotificationSelection>>,
  TError = unknown,
>(options: {
  query: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getNotificationSelection>>, TError, TData>>;
  request?: SecondParameter<typeof request>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetNotificationSelectionSuspense<
  TData = Awaited<ReturnType<typeof getNotificationSelection>>,
  TError = unknown,
>(options?: {
  query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getNotificationSelection>>, TError, TData>>;
  request?: SecondParameter<typeof request>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetNotificationSelectionSuspense<
  TData = Awaited<ReturnType<typeof getNotificationSelection>>,
  TError = unknown,
>(options?: {
  query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getNotificationSelection>>, TError, TData>>;
  request?: SecondParameter<typeof request>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useGetNotificationSelectionSuspense<
  TData = Awaited<ReturnType<typeof getNotificationSelection>>,
  TError = unknown,
>(options?: {
  query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getNotificationSelection>>, TError, TData>>;
  request?: SecondParameter<typeof request>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetNotificationSelectionSuspenseQueryOptions(options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const updateNotificationSelection = (
  notificationSelectionDto: NotificationSelectionDto,
  options?: SecondParameter<typeof request>,
  signal?: AbortSignal,
) => {
  return request<void>(
    {
      url: `/user/notification`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: notificationSelectionDto,
      signal,
    },
    options,
  );
};

export const getUpdateNotificationSelectionMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateNotificationSelection>>,
    TError,
    { data: NotificationSelectionDto },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateNotificationSelection>>,
  TError,
  { data: NotificationSelectionDto },
  TContext
> => {
  const mutationKey = ['updateNotificationSelection'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateNotificationSelection>>,
    { data: NotificationSelectionDto }
  > = (props) => {
    const { data } = props ?? {};

    return updateNotificationSelection(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateNotificationSelectionMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateNotificationSelection>>
>;
export type UpdateNotificationSelectionMutationBody = NotificationSelectionDto;
export type UpdateNotificationSelectionMutationError = unknown;

export const useUpdateNotificationSelection = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateNotificationSelection>>,
    TError,
    { data: NotificationSelectionDto },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateNotificationSelection>>,
  TError,
  { data: NotificationSelectionDto },
  TContext
> => {
  const mutationOptions = getUpdateNotificationSelectionMutationOptions(options);

  return useMutation(mutationOptions);
};

export const getHospitalSelectionsForPractice = (
  practiceId: string,
  options?: SecondParameter<typeof request>,
  signal?: AbortSignal,
) => {
  return request<HospitalSelectionForPracticeDto[]>(
    { url: `/practices/${practiceId}/hospitals`, method: 'GET', signal },
    options,
  );
};

export const getGetHospitalSelectionsForPracticeQueryKey = (practiceId: string) => {
  return [`/practices/${practiceId}/hospitals`] as const;
};

export const getGetHospitalSelectionsForPracticeQueryOptions = <
  TData = Awaited<ReturnType<typeof getHospitalSelectionsForPractice>>,
  TError = unknown,
>(
  practiceId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getHospitalSelectionsForPractice>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetHospitalSelectionsForPracticeQueryKey(practiceId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getHospitalSelectionsForPractice>>> = ({ signal }) =>
    getHospitalSelectionsForPractice(practiceId, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!practiceId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getHospitalSelectionsForPractice>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetHospitalSelectionsForPracticeQueryResult = NonNullable<
  Awaited<ReturnType<typeof getHospitalSelectionsForPractice>>
>;
export type GetHospitalSelectionsForPracticeQueryError = unknown;

export function useGetHospitalSelectionsForPractice<
  TData = Awaited<ReturnType<typeof getHospitalSelectionsForPractice>>,
  TError = unknown,
>(
  practiceId: string,
  options: {
    query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getHospitalSelectionsForPractice>>, TError, TData>> &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getHospitalSelectionsForPractice>>,
          TError,
          Awaited<ReturnType<typeof getHospitalSelectionsForPractice>>
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof request>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetHospitalSelectionsForPractice<
  TData = Awaited<ReturnType<typeof getHospitalSelectionsForPractice>>,
  TError = unknown,
>(
  practiceId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getHospitalSelectionsForPractice>>, TError, TData>> &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getHospitalSelectionsForPractice>>,
          TError,
          Awaited<ReturnType<typeof getHospitalSelectionsForPractice>>
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof request>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetHospitalSelectionsForPractice<
  TData = Awaited<ReturnType<typeof getHospitalSelectionsForPractice>>,
  TError = unknown,
>(
  practiceId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getHospitalSelectionsForPractice>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useGetHospitalSelectionsForPractice<
  TData = Awaited<ReturnType<typeof getHospitalSelectionsForPractice>>,
  TError = unknown,
>(
  practiceId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getHospitalSelectionsForPractice>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetHospitalSelectionsForPracticeQueryOptions(practiceId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getGetHospitalSelectionsForPracticeSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getHospitalSelectionsForPractice>>,
  TError = unknown,
>(
  practiceId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof getHospitalSelectionsForPractice>>, TError, TData>
    >;
    request?: SecondParameter<typeof request>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetHospitalSelectionsForPracticeQueryKey(practiceId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getHospitalSelectionsForPractice>>> = ({ signal }) =>
    getHospitalSelectionsForPractice(practiceId, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getHospitalSelectionsForPractice>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetHospitalSelectionsForPracticeSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof getHospitalSelectionsForPractice>>
>;
export type GetHospitalSelectionsForPracticeSuspenseQueryError = unknown;

export function useGetHospitalSelectionsForPracticeSuspense<
  TData = Awaited<ReturnType<typeof getHospitalSelectionsForPractice>>,
  TError = unknown,
>(
  practiceId: string,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof getHospitalSelectionsForPractice>>, TError, TData>
    >;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetHospitalSelectionsForPracticeSuspense<
  TData = Awaited<ReturnType<typeof getHospitalSelectionsForPractice>>,
  TError = unknown,
>(
  practiceId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof getHospitalSelectionsForPractice>>, TError, TData>
    >;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetHospitalSelectionsForPracticeSuspense<
  TData = Awaited<ReturnType<typeof getHospitalSelectionsForPractice>>,
  TError = unknown,
>(
  practiceId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof getHospitalSelectionsForPractice>>, TError, TData>
    >;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useGetHospitalSelectionsForPracticeSuspense<
  TData = Awaited<ReturnType<typeof getHospitalSelectionsForPractice>>,
  TError = unknown,
>(
  practiceId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof getHospitalSelectionsForPractice>>, TError, TData>
    >;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetHospitalSelectionsForPracticeSuspenseQueryOptions(practiceId, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const updateHospitalSelectionForPractice = (
  practiceId: string,
  hospitalSelectionForPracticeUpdateDto: HospitalSelectionForPracticeUpdateDto,
  options?: SecondParameter<typeof request>,
  signal?: AbortSignal,
) => {
  return request<void>(
    {
      url: `/practices/${practiceId}/hospitals`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: hospitalSelectionForPracticeUpdateDto,
      signal,
    },
    options,
  );
};

export const getUpdateHospitalSelectionForPracticeMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateHospitalSelectionForPractice>>,
    TError,
    { practiceId: string; data: HospitalSelectionForPracticeUpdateDto },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateHospitalSelectionForPractice>>,
  TError,
  { practiceId: string; data: HospitalSelectionForPracticeUpdateDto },
  TContext
> => {
  const mutationKey = ['updateHospitalSelectionForPractice'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateHospitalSelectionForPractice>>,
    { practiceId: string; data: HospitalSelectionForPracticeUpdateDto }
  > = (props) => {
    const { practiceId, data } = props ?? {};

    return updateHospitalSelectionForPractice(practiceId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateHospitalSelectionForPracticeMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateHospitalSelectionForPractice>>
>;
export type UpdateHospitalSelectionForPracticeMutationBody = HospitalSelectionForPracticeUpdateDto;
export type UpdateHospitalSelectionForPracticeMutationError = unknown;

export const useUpdateHospitalSelectionForPractice = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateHospitalSelectionForPractice>>,
    TError,
    { practiceId: string; data: HospitalSelectionForPracticeUpdateDto },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateHospitalSelectionForPractice>>,
  TError,
  { practiceId: string; data: HospitalSelectionForPracticeUpdateDto },
  TContext
> => {
  const mutationOptions = getUpdateHospitalSelectionForPracticeMutationOptions(options);

  return useMutation(mutationOptions);
};

export const unlinkHeurekaPatientById = (
  patientId: string,
  options?: SecondParameter<typeof request>,
  signal?: AbortSignal,
) => {
  return request<void>({ url: `/patients/${patientId}/unlinkheureka`, method: 'POST', signal }, options);
};

export const getUnlinkHeurekaPatientByIdMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof unlinkHeurekaPatientById>>,
    TError,
    { patientId: string },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof unlinkHeurekaPatientById>>,
  TError,
  { patientId: string },
  TContext
> => {
  const mutationKey = ['unlinkHeurekaPatientById'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof unlinkHeurekaPatientById>>, { patientId: string }> = (
    props,
  ) => {
    const { patientId } = props ?? {};

    return unlinkHeurekaPatientById(patientId, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UnlinkHeurekaPatientByIdMutationResult = NonNullable<Awaited<ReturnType<typeof unlinkHeurekaPatientById>>>;

export type UnlinkHeurekaPatientByIdMutationError = unknown;

export const useUnlinkHeurekaPatientById = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof unlinkHeurekaPatientById>>,
    TError,
    { patientId: string },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}): UseMutationResult<
  Awaited<ReturnType<typeof unlinkHeurekaPatientById>>,
  TError,
  { patientId: string },
  TContext
> => {
  const mutationOptions = getUnlinkHeurekaPatientByIdMutationOptions(options);

  return useMutation(mutationOptions);
};

export const setStatusToNeverSeen = (
  patientId: string,
  options?: SecondParameter<typeof request>,
  signal?: AbortSignal,
) => {
  return request<void>({ url: `/patients/${patientId}/status/neverseen`, method: 'POST', signal }, options);
};

export const getSetStatusToNeverSeenMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof setStatusToNeverSeen>>,
    TError,
    { patientId: string },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<Awaited<ReturnType<typeof setStatusToNeverSeen>>, TError, { patientId: string }, TContext> => {
  const mutationKey = ['setStatusToNeverSeen'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof setStatusToNeverSeen>>, { patientId: string }> = (
    props,
  ) => {
    const { patientId } = props ?? {};

    return setStatusToNeverSeen(patientId, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type SetStatusToNeverSeenMutationResult = NonNullable<Awaited<ReturnType<typeof setStatusToNeverSeen>>>;

export type SetStatusToNeverSeenMutationError = unknown;

export const useSetStatusToNeverSeen = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof setStatusToNeverSeen>>,
    TError,
    { patientId: string },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}): UseMutationResult<Awaited<ReturnType<typeof setStatusToNeverSeen>>, TError, { patientId: string }, TContext> => {
  const mutationOptions = getSetStatusToNeverSeenMutationOptions(options);

  return useMutation(mutationOptions);
};

export const linkHeurekaPatientById = (
  patientId: string,
  linkHeurekaDto: LinkHeurekaDto,
  options?: SecondParameter<typeof request>,
  signal?: AbortSignal,
) => {
  return request<void>(
    {
      url: `/patients/${patientId}/linkheureka`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: linkHeurekaDto,
      signal,
    },
    options,
  );
};

export const getLinkHeurekaPatientByIdMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof linkHeurekaPatientById>>,
    TError,
    { patientId: string; data: LinkHeurekaDto },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof linkHeurekaPatientById>>,
  TError,
  { patientId: string; data: LinkHeurekaDto },
  TContext
> => {
  const mutationKey = ['linkHeurekaPatientById'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof linkHeurekaPatientById>>,
    { patientId: string; data: LinkHeurekaDto }
  > = (props) => {
    const { patientId, data } = props ?? {};

    return linkHeurekaPatientById(patientId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type LinkHeurekaPatientByIdMutationResult = NonNullable<Awaited<ReturnType<typeof linkHeurekaPatientById>>>;
export type LinkHeurekaPatientByIdMutationBody = LinkHeurekaDto;
export type LinkHeurekaPatientByIdMutationError = unknown;

export const useLinkHeurekaPatientById = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof linkHeurekaPatientById>>,
    TError,
    { patientId: string; data: LinkHeurekaDto },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}): UseMutationResult<
  Awaited<ReturnType<typeof linkHeurekaPatientById>>,
  TError,
  { patientId: string; data: LinkHeurekaDto },
  TContext
> => {
  const mutationOptions = getLinkHeurekaPatientByIdMutationOptions(options);

  return useMutation(mutationOptions);
};

export const revokeCallcenterAccess = (
  patientId: string,
  options?: SecondParameter<typeof request>,
  signal?: AbortSignal,
) => {
  return request<void>({ url: `/patients/${patientId}/externalaccess/revoke`, method: 'POST', signal }, options);
};

export const getRevokeCallcenterAccessMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof revokeCallcenterAccess>>,
    TError,
    { patientId: string },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<Awaited<ReturnType<typeof revokeCallcenterAccess>>, TError, { patientId: string }, TContext> => {
  const mutationKey = ['revokeCallcenterAccess'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof revokeCallcenterAccess>>, { patientId: string }> = (
    props,
  ) => {
    const { patientId } = props ?? {};

    return revokeCallcenterAccess(patientId, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type RevokeCallcenterAccessMutationResult = NonNullable<Awaited<ReturnType<typeof revokeCallcenterAccess>>>;

export type RevokeCallcenterAccessMutationError = unknown;

export const useRevokeCallcenterAccess = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof revokeCallcenterAccess>>,
    TError,
    { patientId: string },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}): UseMutationResult<Awaited<ReturnType<typeof revokeCallcenterAccess>>, TError, { patientId: string }, TContext> => {
  const mutationOptions = getRevokeCallcenterAccessMutationOptions(options);

  return useMutation(mutationOptions);
};

export const makeExternallyAccessibleNow = (
  patientId: string,
  options?: SecondParameter<typeof request>,
  signal?: AbortSignal,
) => {
  return request<void>({ url: `/patients/${patientId}/externalaccess/grantnow`, method: 'POST', signal }, options);
};

export const getMakeExternallyAccessibleNowMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof makeExternallyAccessibleNow>>,
    TError,
    { patientId: string },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof makeExternallyAccessibleNow>>,
  TError,
  { patientId: string },
  TContext
> => {
  const mutationKey = ['makeExternallyAccessibleNow'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof makeExternallyAccessibleNow>>, { patientId: string }> = (
    props,
  ) => {
    const { patientId } = props ?? {};

    return makeExternallyAccessibleNow(patientId, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type MakeExternallyAccessibleNowMutationResult = NonNullable<
  Awaited<ReturnType<typeof makeExternallyAccessibleNow>>
>;

export type MakeExternallyAccessibleNowMutationError = unknown;

export const useMakeExternallyAccessibleNow = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof makeExternallyAccessibleNow>>,
    TError,
    { patientId: string },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}): UseMutationResult<
  Awaited<ReturnType<typeof makeExternallyAccessibleNow>>,
  TError,
  { patientId: string },
  TContext
> => {
  const mutationOptions = getMakeExternallyAccessibleNowMutationOptions(options);

  return useMutation(mutationOptions);
};

export const grantCallcenterAccess = (
  patientId: string,
  options?: SecondParameter<typeof request>,
  signal?: AbortSignal,
) => {
  return request<void>({ url: `/patients/${patientId}/externalaccess/grant`, method: 'POST', signal }, options);
};

export const getGrantCallcenterAccessMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof grantCallcenterAccess>>,
    TError,
    { patientId: string },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<Awaited<ReturnType<typeof grantCallcenterAccess>>, TError, { patientId: string }, TContext> => {
  const mutationKey = ['grantCallcenterAccess'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof grantCallcenterAccess>>, { patientId: string }> = (
    props,
  ) => {
    const { patientId } = props ?? {};

    return grantCallcenterAccess(patientId, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type GrantCallcenterAccessMutationResult = NonNullable<Awaited<ReturnType<typeof grantCallcenterAccess>>>;

export type GrantCallcenterAccessMutationError = unknown;

export const useGrantCallcenterAccess = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof grantCallcenterAccess>>,
    TError,
    { patientId: string },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}): UseMutationResult<Awaited<ReturnType<typeof grantCallcenterAccess>>, TError, { patientId: string }, TContext> => {
  const mutationOptions = getGrantCallcenterAccessMutationOptions(options);

  return useMutation(mutationOptions);
};

export const setExternalAccessRevokedByPractice = (
  params: SetExternalAccessRevokedByPracticeParams,
  options?: SecondParameter<typeof request>,
  signal?: AbortSignal,
) => {
  return request<void>({ url: `/patients/externalaccess/revoke`, method: 'POST', params, signal }, options);
};

export const getSetExternalAccessRevokedByPracticeMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof setExternalAccessRevokedByPractice>>,
    TError,
    { params: SetExternalAccessRevokedByPracticeParams },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof setExternalAccessRevokedByPractice>>,
  TError,
  { params: SetExternalAccessRevokedByPracticeParams },
  TContext
> => {
  const mutationKey = ['setExternalAccessRevokedByPractice'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof setExternalAccessRevokedByPractice>>,
    { params: SetExternalAccessRevokedByPracticeParams }
  > = (props) => {
    const { params } = props ?? {};

    return setExternalAccessRevokedByPractice(params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type SetExternalAccessRevokedByPracticeMutationResult = NonNullable<
  Awaited<ReturnType<typeof setExternalAccessRevokedByPractice>>
>;

export type SetExternalAccessRevokedByPracticeMutationError = unknown;

export const useSetExternalAccessRevokedByPractice = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof setExternalAccessRevokedByPractice>>,
    TError,
    { params: SetExternalAccessRevokedByPracticeParams },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}): UseMutationResult<
  Awaited<ReturnType<typeof setExternalAccessRevokedByPractice>>,
  TError,
  { params: SetExternalAccessRevokedByPracticeParams },
  TContext
> => {
  const mutationOptions = getSetExternalAccessRevokedByPracticeMutationOptions(options);

  return useMutation(mutationOptions);
};

export const makeExternallyAccessibleNowByPractice = (
  params: MakeExternallyAccessibleNowByPracticeParams,
  options?: SecondParameter<typeof request>,
  signal?: AbortSignal,
) => {
  return request<void>({ url: `/patients/externalaccess/grantnow`, method: 'POST', params, signal }, options);
};

export const getMakeExternallyAccessibleNowByPracticeMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof makeExternallyAccessibleNowByPractice>>,
    TError,
    { params: MakeExternallyAccessibleNowByPracticeParams },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof makeExternallyAccessibleNowByPractice>>,
  TError,
  { params: MakeExternallyAccessibleNowByPracticeParams },
  TContext
> => {
  const mutationKey = ['makeExternallyAccessibleNowByPractice'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof makeExternallyAccessibleNowByPractice>>,
    { params: MakeExternallyAccessibleNowByPracticeParams }
  > = (props) => {
    const { params } = props ?? {};

    return makeExternallyAccessibleNowByPractice(params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type MakeExternallyAccessibleNowByPracticeMutationResult = NonNullable<
  Awaited<ReturnType<typeof makeExternallyAccessibleNowByPractice>>
>;

export type MakeExternallyAccessibleNowByPracticeMutationError = unknown;

export const useMakeExternallyAccessibleNowByPractice = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof makeExternallyAccessibleNowByPractice>>,
    TError,
    { params: MakeExternallyAccessibleNowByPracticeParams },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}): UseMutationResult<
  Awaited<ReturnType<typeof makeExternallyAccessibleNowByPractice>>,
  TError,
  { params: MakeExternallyAccessibleNowByPracticeParams },
  TContext
> => {
  const mutationOptions = getMakeExternallyAccessibleNowByPracticeMutationOptions(options);

  return useMutation(mutationOptions);
};

export const setExternalAccessGrantedByPractice = (
  params: SetExternalAccessGrantedByPracticeParams,
  options?: SecondParameter<typeof request>,
  signal?: AbortSignal,
) => {
  return request<void>({ url: `/patients/externalaccess/grant`, method: 'POST', params, signal }, options);
};

export const getSetExternalAccessGrantedByPracticeMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof setExternalAccessGrantedByPractice>>,
    TError,
    { params: SetExternalAccessGrantedByPracticeParams },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof setExternalAccessGrantedByPractice>>,
  TError,
  { params: SetExternalAccessGrantedByPracticeParams },
  TContext
> => {
  const mutationKey = ['setExternalAccessGrantedByPractice'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof setExternalAccessGrantedByPractice>>,
    { params: SetExternalAccessGrantedByPracticeParams }
  > = (props) => {
    const { params } = props ?? {};

    return setExternalAccessGrantedByPractice(params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type SetExternalAccessGrantedByPracticeMutationResult = NonNullable<
  Awaited<ReturnType<typeof setExternalAccessGrantedByPractice>>
>;

export type SetExternalAccessGrantedByPracticeMutationError = unknown;

export const useSetExternalAccessGrantedByPractice = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof setExternalAccessGrantedByPractice>>,
    TError,
    { params: SetExternalAccessGrantedByPracticeParams },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}): UseMutationResult<
  Awaited<ReturnType<typeof setExternalAccessGrantedByPractice>>,
  TError,
  { params: SetExternalAccessGrantedByPracticeParams },
  TContext
> => {
  const mutationOptions = getSetExternalAccessGrantedByPracticeMutationOptions(options);

  return useMutation(mutationOptions);
};

export const createPatientDataRequest = (
  createPatientDataRequestBody: CreatePatientDataRequestBody,
  options?: SecondParameter<typeof request>,
  signal?: AbortSignal,
) => {
  const formUrlEncoded = new URLSearchParams();
  formUrlEncoded.append('ahv', createPatientDataRequestBody.ahv);
  formUrlEncoded.append('birthDate', createPatientDataRequestBody.birthDate);
  formUrlEncoded.append('pid', createPatientDataRequestBody.pid);
  formUrlEncoded.append('fid', createPatientDataRequestBody.fid);
  formUrlEncoded.append('hospitalName', createPatientDataRequestBody.hospitalName);
  if (createPatientDataRequestBody.department !== undefined) {
    formUrlEncoded.append('department', createPatientDataRequestBody.department);
  }
  formUrlEncoded.append('confirmationEmail', createPatientDataRequestBody.confirmationEmail);
  formUrlEncoded.append('requestId', createPatientDataRequestBody.requestId);
  formUrlEncoded.append('created', createPatientDataRequestBody.created);
  formUrlEncoded.append('hmac', createPatientDataRequestBody.hmac);

  return request<string>(
    {
      url: `/hospital/patient/request`,
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      data: formUrlEncoded,
      signal,
    },
    options,
  );
};

export const getCreatePatientDataRequestMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createPatientDataRequest>>,
    TError,
    { data: CreatePatientDataRequestBody },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createPatientDataRequest>>,
  TError,
  { data: CreatePatientDataRequestBody },
  TContext
> => {
  const mutationKey = ['createPatientDataRequest'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createPatientDataRequest>>,
    { data: CreatePatientDataRequestBody }
  > = (props) => {
    const { data } = props ?? {};

    return createPatientDataRequest(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreatePatientDataRequestMutationResult = NonNullable<Awaited<ReturnType<typeof createPatientDataRequest>>>;
export type CreatePatientDataRequestMutationBody = CreatePatientDataRequestBody;
export type CreatePatientDataRequestMutationError = unknown;

export const useCreatePatientDataRequest = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createPatientDataRequest>>,
    TError,
    { data: CreatePatientDataRequestBody },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}): UseMutationResult<
  Awaited<ReturnType<typeof createPatientDataRequest>>,
  TError,
  { data: CreatePatientDataRequestBody },
  TContext
> => {
  const mutationOptions = getCreatePatientDataRequestMutationOptions(options);

  return useMutation(mutationOptions);
};

export const prepareRedirectToRevokeHeurekaPortalScreen = (
  practiceId: string,
  options?: SecondParameter<typeof request>,
  signal?: AbortSignal,
) => {
  return request<string>({ url: `/heureka/practices/${practiceId}/prepare-revoke`, method: 'POST', signal }, options);
};

export const getPrepareRedirectToRevokeHeurekaPortalScreenMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof prepareRedirectToRevokeHeurekaPortalScreen>>,
    TError,
    { practiceId: string },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof prepareRedirectToRevokeHeurekaPortalScreen>>,
  TError,
  { practiceId: string },
  TContext
> => {
  const mutationKey = ['prepareRedirectToRevokeHeurekaPortalScreen'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof prepareRedirectToRevokeHeurekaPortalScreen>>,
    { practiceId: string }
  > = (props) => {
    const { practiceId } = props ?? {};

    return prepareRedirectToRevokeHeurekaPortalScreen(practiceId, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PrepareRedirectToRevokeHeurekaPortalScreenMutationResult = NonNullable<
  Awaited<ReturnType<typeof prepareRedirectToRevokeHeurekaPortalScreen>>
>;

export type PrepareRedirectToRevokeHeurekaPortalScreenMutationError = unknown;

export const usePrepareRedirectToRevokeHeurekaPortalScreen = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof prepareRedirectToRevokeHeurekaPortalScreen>>,
    TError,
    { practiceId: string },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}): UseMutationResult<
  Awaited<ReturnType<typeof prepareRedirectToRevokeHeurekaPortalScreen>>,
  TError,
  { practiceId: string },
  TContext
> => {
  const mutationOptions = getPrepareRedirectToRevokeHeurekaPortalScreenMutationOptions(options);

  return useMutation(mutationOptions);
};

export const startEmergencyService = (
  params: StartEmergencyServiceParams,
  options?: SecondParameter<typeof request>,
  signal?: AbortSignal,
) => {
  return request<void>({ url: `/emergency-service/start`, method: 'POST', params, signal }, options);
};

export const getStartEmergencyServiceMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof startEmergencyService>>,
    TError,
    { params: StartEmergencyServiceParams },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof startEmergencyService>>,
  TError,
  { params: StartEmergencyServiceParams },
  TContext
> => {
  const mutationKey = ['startEmergencyService'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof startEmergencyService>>,
    { params: StartEmergencyServiceParams }
  > = (props) => {
    const { params } = props ?? {};

    return startEmergencyService(params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type StartEmergencyServiceMutationResult = NonNullable<Awaited<ReturnType<typeof startEmergencyService>>>;

export type StartEmergencyServiceMutationError = unknown;

export const useStartEmergencyService = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof startEmergencyService>>,
    TError,
    { params: StartEmergencyServiceParams },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}): UseMutationResult<
  Awaited<ReturnType<typeof startEmergencyService>>,
  TError,
  { params: StartEmergencyServiceParams },
  TContext
> => {
  const mutationOptions = getStartEmergencyServiceMutationOptions(options);

  return useMutation(mutationOptions);
};

export const createDeputy = (
  createDeputyDto: CreateDeputyDto,
  options?: SecondParameter<typeof request>,
  signal?: AbortSignal,
) => {
  return request<void>(
    {
      url: `/deputies`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: createDeputyDto,
      signal,
    },
    options,
  );
};

export const getCreateDeputyMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof createDeputy>>, TError, { data: CreateDeputyDto }, TContext>;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<Awaited<ReturnType<typeof createDeputy>>, TError, { data: CreateDeputyDto }, TContext> => {
  const mutationKey = ['createDeputy'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof createDeputy>>, { data: CreateDeputyDto }> = (props) => {
    const { data } = props ?? {};

    return createDeputy(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateDeputyMutationResult = NonNullable<Awaited<ReturnType<typeof createDeputy>>>;
export type CreateDeputyMutationBody = CreateDeputyDto;
export type CreateDeputyMutationError = unknown;

export const useCreateDeputy = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof createDeputy>>, TError, { data: CreateDeputyDto }, TContext>;
  request?: SecondParameter<typeof request>;
}): UseMutationResult<Awaited<ReturnType<typeof createDeputy>>, TError, { data: CreateDeputyDto }, TContext> => {
  const mutationOptions = getCreateDeputyMutationOptions(options);

  return useMutation(mutationOptions);
};

export const revokeDeputy = (id: string, options?: SecondParameter<typeof request>, signal?: AbortSignal) => {
  return request<void>({ url: `/deputies/${id}/revoke`, method: 'POST', signal }, options);
};

export const getRevokeDeputyMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof revokeDeputy>>, TError, { id: string }, TContext>;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<Awaited<ReturnType<typeof revokeDeputy>>, TError, { id: string }, TContext> => {
  const mutationKey = ['revokeDeputy'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof revokeDeputy>>, { id: string }> = (props) => {
    const { id } = props ?? {};

    return revokeDeputy(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type RevokeDeputyMutationResult = NonNullable<Awaited<ReturnType<typeof revokeDeputy>>>;

export type RevokeDeputyMutationError = unknown;

export const useRevokeDeputy = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof revokeDeputy>>, TError, { id: string }, TContext>;
  request?: SecondParameter<typeof request>;
}): UseMutationResult<Awaited<ReturnType<typeof revokeDeputy>>, TError, { id: string }, TContext> => {
  const mutationOptions = getRevokeDeputyMutationOptions(options);

  return useMutation(mutationOptions);
};

export const rejectDeputy = (
  id: string,
  rejectDeputyDto: RejectDeputyDto,
  options?: SecondParameter<typeof request>,
  signal?: AbortSignal,
) => {
  return request<void>(
    {
      url: `/deputies/${id}/reject`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: rejectDeputyDto,
      signal,
    },
    options,
  );
};

export const getRejectDeputyMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof rejectDeputy>>,
    TError,
    { id: string; data: RejectDeputyDto },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof rejectDeputy>>,
  TError,
  { id: string; data: RejectDeputyDto },
  TContext
> => {
  const mutationKey = ['rejectDeputy'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof rejectDeputy>>,
    { id: string; data: RejectDeputyDto }
  > = (props) => {
    const { id, data } = props ?? {};

    return rejectDeputy(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type RejectDeputyMutationResult = NonNullable<Awaited<ReturnType<typeof rejectDeputy>>>;
export type RejectDeputyMutationBody = RejectDeputyDto;
export type RejectDeputyMutationError = unknown;

export const useRejectDeputy = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof rejectDeputy>>,
    TError,
    { id: string; data: RejectDeputyDto },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}): UseMutationResult<
  Awaited<ReturnType<typeof rejectDeputy>>,
  TError,
  { id: string; data: RejectDeputyDto },
  TContext
> => {
  const mutationOptions = getRejectDeputyMutationOptions(options);

  return useMutation(mutationOptions);
};

export const acceptDeputy = (id: string, options?: SecondParameter<typeof request>, signal?: AbortSignal) => {
  return request<void>({ url: `/deputies/${id}/accept`, method: 'POST', signal }, options);
};

export const getAcceptDeputyMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof acceptDeputy>>, TError, { id: string }, TContext>;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<Awaited<ReturnType<typeof acceptDeputy>>, TError, { id: string }, TContext> => {
  const mutationKey = ['acceptDeputy'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof acceptDeputy>>, { id: string }> = (props) => {
    const { id } = props ?? {};

    return acceptDeputy(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type AcceptDeputyMutationResult = NonNullable<Awaited<ReturnType<typeof acceptDeputy>>>;

export type AcceptDeputyMutationError = unknown;

export const useAcceptDeputy = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof acceptDeputy>>, TError, { id: string }, TContext>;
  request?: SecondParameter<typeof request>;
}): UseMutationResult<Awaited<ReturnType<typeof acceptDeputy>>, TError, { id: string }, TContext> => {
  const mutationOptions = getAcceptDeputyMutationOptions(options);

  return useMutation(mutationOptions);
};

export const sharePatientWithPartner = (
  patientId: string,
  params: SharePatientWithPartnerParams,
  options?: SecondParameter<typeof request>,
  signal?: AbortSignal,
) => {
  return request<void>({ url: `/callcenter/patients/${patientId}/share`, method: 'POST', params, signal }, options);
};

export const getSharePatientWithPartnerMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof sharePatientWithPartner>>,
    TError,
    { patientId: string; params: SharePatientWithPartnerParams },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof sharePatientWithPartner>>,
  TError,
  { patientId: string; params: SharePatientWithPartnerParams },
  TContext
> => {
  const mutationKey = ['sharePatientWithPartner'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof sharePatientWithPartner>>,
    { patientId: string; params: SharePatientWithPartnerParams }
  > = (props) => {
    const { patientId, params } = props ?? {};

    return sharePatientWithPartner(patientId, params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type SharePatientWithPartnerMutationResult = NonNullable<Awaited<ReturnType<typeof sharePatientWithPartner>>>;

export type SharePatientWithPartnerMutationError = unknown;

export const useSharePatientWithPartner = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof sharePatientWithPartner>>,
    TError,
    { patientId: string; params: SharePatientWithPartnerParams },
    TContext
  >;
  request?: SecondParameter<typeof request>;
}): UseMutationResult<
  Awaited<ReturnType<typeof sharePatientWithPartner>>,
  TError,
  { patientId: string; params: SharePatientWithPartnerParams },
  TContext
> => {
  const mutationOptions = getSharePatientWithPartnerMutationOptions(options);

  return useMutation(mutationOptions);
};

export const getSelf = (options?: SecondParameter<typeof request>, signal?: AbortSignal) => {
  return request<WhoamiDto>({ url: `/whoami`, method: 'GET', signal }, options);
};

export const getGetSelfQueryKey = () => {
  return [`/whoami`] as const;
};

export const getGetSelfQueryOptions = <TData = Awaited<ReturnType<typeof getSelf>>, TError = unknown>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getSelf>>, TError, TData>>;
  request?: SecondParameter<typeof request>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetSelfQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSelf>>> = ({ signal }) => getSelf(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getSelf>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetSelfQueryResult = NonNullable<Awaited<ReturnType<typeof getSelf>>>;
export type GetSelfQueryError = unknown;

export function useGetSelf<TData = Awaited<ReturnType<typeof getSelf>>, TError = unknown>(options: {
  query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getSelf>>, TError, TData>> &
    Pick<
      DefinedInitialDataOptions<Awaited<ReturnType<typeof getSelf>>, TError, Awaited<ReturnType<typeof getSelf>>>,
      'initialData'
    >;
  request?: SecondParameter<typeof request>;
}): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetSelf<TData = Awaited<ReturnType<typeof getSelf>>, TError = unknown>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getSelf>>, TError, TData>> &
    Pick<
      UndefinedInitialDataOptions<Awaited<ReturnType<typeof getSelf>>, TError, Awaited<ReturnType<typeof getSelf>>>,
      'initialData'
    >;
  request?: SecondParameter<typeof request>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetSelf<TData = Awaited<ReturnType<typeof getSelf>>, TError = unknown>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getSelf>>, TError, TData>>;
  request?: SecondParameter<typeof request>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useGetSelf<TData = Awaited<ReturnType<typeof getSelf>>, TError = unknown>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getSelf>>, TError, TData>>;
  request?: SecondParameter<typeof request>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetSelfQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getGetSelfSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getSelf>>,
  TError = unknown,
>(options?: {
  query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getSelf>>, TError, TData>>;
  request?: SecondParameter<typeof request>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetSelfQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSelf>>> = ({ signal }) => getSelf(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getSelf>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetSelfSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getSelf>>>;
export type GetSelfSuspenseQueryError = unknown;

export function useGetSelfSuspense<TData = Awaited<ReturnType<typeof getSelf>>, TError = unknown>(options: {
  query: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getSelf>>, TError, TData>>;
  request?: SecondParameter<typeof request>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetSelfSuspense<TData = Awaited<ReturnType<typeof getSelf>>, TError = unknown>(options?: {
  query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getSelf>>, TError, TData>>;
  request?: SecondParameter<typeof request>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetSelfSuspense<TData = Awaited<ReturnType<typeof getSelf>>, TError = unknown>(options?: {
  query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getSelf>>, TError, TData>>;
  request?: SecondParameter<typeof request>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useGetSelfSuspense<TData = Awaited<ReturnType<typeof getSelf>>, TError = unknown>(options?: {
  query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getSelf>>, TError, TData>>;
  request?: SecondParameter<typeof request>;
}): UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetSelfSuspenseQueryOptions(options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getPatientsSharedWithPractice = (
  params: GetPatientsSharedWithPracticeParams,
  options?: SecondParameter<typeof request>,
  signal?: AbortSignal,
) => {
  return request<SharedPatientDto[]>({ url: `/shared-patients`, method: 'GET', params, signal }, options);
};

export const getGetPatientsSharedWithPracticeQueryKey = (params: GetPatientsSharedWithPracticeParams) => {
  return [`/shared-patients`, ...(params ? [params] : [])] as const;
};

export const getGetPatientsSharedWithPracticeQueryOptions = <
  TData = Awaited<ReturnType<typeof getPatientsSharedWithPractice>>,
  TError = unknown,
>(
  params: GetPatientsSharedWithPracticeParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPatientsSharedWithPractice>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetPatientsSharedWithPracticeQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPatientsSharedWithPractice>>> = ({ signal }) =>
    getPatientsSharedWithPractice(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getPatientsSharedWithPractice>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetPatientsSharedWithPracticeQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPatientsSharedWithPractice>>
>;
export type GetPatientsSharedWithPracticeQueryError = unknown;

export function useGetPatientsSharedWithPractice<
  TData = Awaited<ReturnType<typeof getPatientsSharedWithPractice>>,
  TError = unknown,
>(
  params: GetPatientsSharedWithPracticeParams,
  options: {
    query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPatientsSharedWithPractice>>, TError, TData>> &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getPatientsSharedWithPractice>>,
          TError,
          Awaited<ReturnType<typeof getPatientsSharedWithPractice>>
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof request>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetPatientsSharedWithPractice<
  TData = Awaited<ReturnType<typeof getPatientsSharedWithPractice>>,
  TError = unknown,
>(
  params: GetPatientsSharedWithPracticeParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPatientsSharedWithPractice>>, TError, TData>> &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getPatientsSharedWithPractice>>,
          TError,
          Awaited<ReturnType<typeof getPatientsSharedWithPractice>>
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof request>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetPatientsSharedWithPractice<
  TData = Awaited<ReturnType<typeof getPatientsSharedWithPractice>>,
  TError = unknown,
>(
  params: GetPatientsSharedWithPracticeParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPatientsSharedWithPractice>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useGetPatientsSharedWithPractice<
  TData = Awaited<ReturnType<typeof getPatientsSharedWithPractice>>,
  TError = unknown,
>(
  params: GetPatientsSharedWithPracticeParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPatientsSharedWithPractice>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetPatientsSharedWithPracticeQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getGetPatientsSharedWithPracticeSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getPatientsSharedWithPractice>>,
  TError = unknown,
>(
  params: GetPatientsSharedWithPracticeParams,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getPatientsSharedWithPractice>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetPatientsSharedWithPracticeQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPatientsSharedWithPractice>>> = ({ signal }) =>
    getPatientsSharedWithPractice(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getPatientsSharedWithPractice>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetPatientsSharedWithPracticeSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPatientsSharedWithPractice>>
>;
export type GetPatientsSharedWithPracticeSuspenseQueryError = unknown;

export function useGetPatientsSharedWithPracticeSuspense<
  TData = Awaited<ReturnType<typeof getPatientsSharedWithPractice>>,
  TError = unknown,
>(
  params: GetPatientsSharedWithPracticeParams,
  options: {
    query: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getPatientsSharedWithPractice>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetPatientsSharedWithPracticeSuspense<
  TData = Awaited<ReturnType<typeof getPatientsSharedWithPractice>>,
  TError = unknown,
>(
  params: GetPatientsSharedWithPracticeParams,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getPatientsSharedWithPractice>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetPatientsSharedWithPracticeSuspense<
  TData = Awaited<ReturnType<typeof getPatientsSharedWithPractice>>,
  TError = unknown,
>(
  params: GetPatientsSharedWithPracticeParams,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getPatientsSharedWithPractice>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useGetPatientsSharedWithPracticeSuspense<
  TData = Awaited<ReturnType<typeof getPatientsSharedWithPractice>>,
  TError = unknown,
>(
  params: GetPatientsSharedWithPracticeParams,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getPatientsSharedWithPractice>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetPatientsSharedWithPracticeSuspenseQueryOptions(params, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getPatientsByPractice = (
  params: GetPatientsByPracticeParams,
  options?: SecondParameter<typeof request>,
  signal?: AbortSignal,
) => {
  return request<PatientDto[]>({ url: `/patients`, method: 'GET', params, signal }, options);
};

export const getGetPatientsByPracticeQueryKey = (params: GetPatientsByPracticeParams) => {
  return [`/patients`, ...(params ? [params] : [])] as const;
};

export const getGetPatientsByPracticeQueryOptions = <
  TData = Awaited<ReturnType<typeof getPatientsByPractice>>,
  TError = unknown,
>(
  params: GetPatientsByPracticeParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPatientsByPractice>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetPatientsByPracticeQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPatientsByPractice>>> = ({ signal }) =>
    getPatientsByPractice(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getPatientsByPractice>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetPatientsByPracticeQueryResult = NonNullable<Awaited<ReturnType<typeof getPatientsByPractice>>>;
export type GetPatientsByPracticeQueryError = unknown;

export function useGetPatientsByPractice<TData = Awaited<ReturnType<typeof getPatientsByPractice>>, TError = unknown>(
  params: GetPatientsByPracticeParams,
  options: {
    query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPatientsByPractice>>, TError, TData>> &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getPatientsByPractice>>,
          TError,
          Awaited<ReturnType<typeof getPatientsByPractice>>
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof request>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetPatientsByPractice<TData = Awaited<ReturnType<typeof getPatientsByPractice>>, TError = unknown>(
  params: GetPatientsByPracticeParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPatientsByPractice>>, TError, TData>> &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getPatientsByPractice>>,
          TError,
          Awaited<ReturnType<typeof getPatientsByPractice>>
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof request>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetPatientsByPractice<TData = Awaited<ReturnType<typeof getPatientsByPractice>>, TError = unknown>(
  params: GetPatientsByPracticeParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPatientsByPractice>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useGetPatientsByPractice<TData = Awaited<ReturnType<typeof getPatientsByPractice>>, TError = unknown>(
  params: GetPatientsByPracticeParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPatientsByPractice>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetPatientsByPracticeQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getGetPatientsByPracticeSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getPatientsByPractice>>,
  TError = unknown,
>(
  params: GetPatientsByPracticeParams,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getPatientsByPractice>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetPatientsByPracticeQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPatientsByPractice>>> = ({ signal }) =>
    getPatientsByPractice(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getPatientsByPractice>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetPatientsByPracticeSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getPatientsByPractice>>>;
export type GetPatientsByPracticeSuspenseQueryError = unknown;

export function useGetPatientsByPracticeSuspense<
  TData = Awaited<ReturnType<typeof getPatientsByPractice>>,
  TError = unknown,
>(
  params: GetPatientsByPracticeParams,
  options: {
    query: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getPatientsByPractice>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetPatientsByPracticeSuspense<
  TData = Awaited<ReturnType<typeof getPatientsByPractice>>,
  TError = unknown,
>(
  params: GetPatientsByPracticeParams,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getPatientsByPractice>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetPatientsByPracticeSuspense<
  TData = Awaited<ReturnType<typeof getPatientsByPractice>>,
  TError = unknown,
>(
  params: GetPatientsByPracticeParams,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getPatientsByPractice>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useGetPatientsByPracticeSuspense<
  TData = Awaited<ReturnType<typeof getPatientsByPractice>>,
  TError = unknown,
>(
  params: GetPatientsByPracticeParams,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getPatientsByPractice>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetPatientsByPracticeSuspenseQueryOptions(params, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getPatientDetails = (
  patientId: string,
  options?: SecondParameter<typeof request>,
  signal?: AbortSignal,
) => {
  return request<PatientDto>({ url: `/patients/${patientId}`, method: 'GET', signal }, options);
};

export const getGetPatientDetailsQueryKey = (patientId: string) => {
  return [`/patients/${patientId}`] as const;
};

export const getGetPatientDetailsQueryOptions = <
  TData = Awaited<ReturnType<typeof getPatientDetails>>,
  TError = unknown,
>(
  patientId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPatientDetails>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetPatientDetailsQueryKey(patientId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPatientDetails>>> = ({ signal }) =>
    getPatientDetails(patientId, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!patientId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getPatientDetails>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetPatientDetailsQueryResult = NonNullable<Awaited<ReturnType<typeof getPatientDetails>>>;
export type GetPatientDetailsQueryError = unknown;

export function useGetPatientDetails<TData = Awaited<ReturnType<typeof getPatientDetails>>, TError = unknown>(
  patientId: string,
  options: {
    query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPatientDetails>>, TError, TData>> &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getPatientDetails>>,
          TError,
          Awaited<ReturnType<typeof getPatientDetails>>
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof request>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetPatientDetails<TData = Awaited<ReturnType<typeof getPatientDetails>>, TError = unknown>(
  patientId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPatientDetails>>, TError, TData>> &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getPatientDetails>>,
          TError,
          Awaited<ReturnType<typeof getPatientDetails>>
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof request>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetPatientDetails<TData = Awaited<ReturnType<typeof getPatientDetails>>, TError = unknown>(
  patientId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPatientDetails>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useGetPatientDetails<TData = Awaited<ReturnType<typeof getPatientDetails>>, TError = unknown>(
  patientId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPatientDetails>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetPatientDetailsQueryOptions(patientId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getGetPatientDetailsSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getPatientDetails>>,
  TError = unknown,
>(
  patientId: string,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getPatientDetails>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetPatientDetailsQueryKey(patientId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPatientDetails>>> = ({ signal }) =>
    getPatientDetails(patientId, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getPatientDetails>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetPatientDetailsSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getPatientDetails>>>;
export type GetPatientDetailsSuspenseQueryError = unknown;

export function useGetPatientDetailsSuspense<TData = Awaited<ReturnType<typeof getPatientDetails>>, TError = unknown>(
  patientId: string,
  options: {
    query: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getPatientDetails>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetPatientDetailsSuspense<TData = Awaited<ReturnType<typeof getPatientDetails>>, TError = unknown>(
  patientId: string,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getPatientDetails>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetPatientDetailsSuspense<TData = Awaited<ReturnType<typeof getPatientDetails>>, TError = unknown>(
  patientId: string,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getPatientDetails>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useGetPatientDetailsSuspense<TData = Awaited<ReturnType<typeof getPatientDetails>>, TError = unknown>(
  patientId: string,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getPatientDetails>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetPatientDetailsSuspenseQueryOptions(patientId, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const denySharePatientWithHospital = (
  params: DenySharePatientWithHospitalParams,
  options?: SecondParameter<typeof request>,
  signal?: AbortSignal,
) => {
  return request<DenySharePatientWithHospital200>(
    { url: `/hospital/patient/share/deny`, method: 'GET', params, signal },
    options,
  );
};

export const getDenySharePatientWithHospitalQueryKey = (params: DenySharePatientWithHospitalParams) => {
  return [`/hospital/patient/share/deny`, ...(params ? [params] : [])] as const;
};

export const getDenySharePatientWithHospitalQueryOptions = <
  TData = Awaited<ReturnType<typeof denySharePatientWithHospital>>,
  TError = unknown,
>(
  params: DenySharePatientWithHospitalParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof denySharePatientWithHospital>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getDenySharePatientWithHospitalQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof denySharePatientWithHospital>>> = ({ signal }) =>
    denySharePatientWithHospital(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof denySharePatientWithHospital>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type DenySharePatientWithHospitalQueryResult = NonNullable<
  Awaited<ReturnType<typeof denySharePatientWithHospital>>
>;
export type DenySharePatientWithHospitalQueryError = unknown;

export function useDenySharePatientWithHospital<
  TData = Awaited<ReturnType<typeof denySharePatientWithHospital>>,
  TError = unknown,
>(
  params: DenySharePatientWithHospitalParams,
  options: {
    query: Partial<UseQueryOptions<Awaited<ReturnType<typeof denySharePatientWithHospital>>, TError, TData>> &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof denySharePatientWithHospital>>,
          TError,
          Awaited<ReturnType<typeof denySharePatientWithHospital>>
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof request>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useDenySharePatientWithHospital<
  TData = Awaited<ReturnType<typeof denySharePatientWithHospital>>,
  TError = unknown,
>(
  params: DenySharePatientWithHospitalParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof denySharePatientWithHospital>>, TError, TData>> &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof denySharePatientWithHospital>>,
          TError,
          Awaited<ReturnType<typeof denySharePatientWithHospital>>
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof request>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useDenySharePatientWithHospital<
  TData = Awaited<ReturnType<typeof denySharePatientWithHospital>>,
  TError = unknown,
>(
  params: DenySharePatientWithHospitalParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof denySharePatientWithHospital>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useDenySharePatientWithHospital<
  TData = Awaited<ReturnType<typeof denySharePatientWithHospital>>,
  TError = unknown,
>(
  params: DenySharePatientWithHospitalParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof denySharePatientWithHospital>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getDenySharePatientWithHospitalQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getDenySharePatientWithHospitalSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof denySharePatientWithHospital>>,
  TError = unknown,
>(
  params: DenySharePatientWithHospitalParams,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof denySharePatientWithHospital>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getDenySharePatientWithHospitalQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof denySharePatientWithHospital>>> = ({ signal }) =>
    denySharePatientWithHospital(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof denySharePatientWithHospital>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type DenySharePatientWithHospitalSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof denySharePatientWithHospital>>
>;
export type DenySharePatientWithHospitalSuspenseQueryError = unknown;

export function useDenySharePatientWithHospitalSuspense<
  TData = Awaited<ReturnType<typeof denySharePatientWithHospital>>,
  TError = unknown,
>(
  params: DenySharePatientWithHospitalParams,
  options: {
    query: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof denySharePatientWithHospital>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useDenySharePatientWithHospitalSuspense<
  TData = Awaited<ReturnType<typeof denySharePatientWithHospital>>,
  TError = unknown,
>(
  params: DenySharePatientWithHospitalParams,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof denySharePatientWithHospital>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useDenySharePatientWithHospitalSuspense<
  TData = Awaited<ReturnType<typeof denySharePatientWithHospital>>,
  TError = unknown,
>(
  params: DenySharePatientWithHospitalParams,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof denySharePatientWithHospital>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useDenySharePatientWithHospitalSuspense<
  TData = Awaited<ReturnType<typeof denySharePatientWithHospital>>,
  TError = unknown,
>(
  params: DenySharePatientWithHospitalParams,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof denySharePatientWithHospital>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getDenySharePatientWithHospitalSuspenseQueryOptions(params, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const acceptSharePatientWithHospital = (
  params: AcceptSharePatientWithHospitalParams,
  options?: SecondParameter<typeof request>,
  signal?: AbortSignal,
) => {
  return request<AcceptSharePatientWithHospital200>(
    { url: `/hospital/patient/share/accept`, method: 'GET', params, signal },
    options,
  );
};

export const getAcceptSharePatientWithHospitalQueryKey = (params: AcceptSharePatientWithHospitalParams) => {
  return [`/hospital/patient/share/accept`, ...(params ? [params] : [])] as const;
};

export const getAcceptSharePatientWithHospitalQueryOptions = <
  TData = Awaited<ReturnType<typeof acceptSharePatientWithHospital>>,
  TError = unknown,
>(
  params: AcceptSharePatientWithHospitalParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof acceptSharePatientWithHospital>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getAcceptSharePatientWithHospitalQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof acceptSharePatientWithHospital>>> = ({ signal }) =>
    acceptSharePatientWithHospital(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof acceptSharePatientWithHospital>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type AcceptSharePatientWithHospitalQueryResult = NonNullable<
  Awaited<ReturnType<typeof acceptSharePatientWithHospital>>
>;
export type AcceptSharePatientWithHospitalQueryError = unknown;

export function useAcceptSharePatientWithHospital<
  TData = Awaited<ReturnType<typeof acceptSharePatientWithHospital>>,
  TError = unknown,
>(
  params: AcceptSharePatientWithHospitalParams,
  options: {
    query: Partial<UseQueryOptions<Awaited<ReturnType<typeof acceptSharePatientWithHospital>>, TError, TData>> &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof acceptSharePatientWithHospital>>,
          TError,
          Awaited<ReturnType<typeof acceptSharePatientWithHospital>>
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof request>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useAcceptSharePatientWithHospital<
  TData = Awaited<ReturnType<typeof acceptSharePatientWithHospital>>,
  TError = unknown,
>(
  params: AcceptSharePatientWithHospitalParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof acceptSharePatientWithHospital>>, TError, TData>> &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof acceptSharePatientWithHospital>>,
          TError,
          Awaited<ReturnType<typeof acceptSharePatientWithHospital>>
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof request>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useAcceptSharePatientWithHospital<
  TData = Awaited<ReturnType<typeof acceptSharePatientWithHospital>>,
  TError = unknown,
>(
  params: AcceptSharePatientWithHospitalParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof acceptSharePatientWithHospital>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useAcceptSharePatientWithHospital<
  TData = Awaited<ReturnType<typeof acceptSharePatientWithHospital>>,
  TError = unknown,
>(
  params: AcceptSharePatientWithHospitalParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof acceptSharePatientWithHospital>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getAcceptSharePatientWithHospitalQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getAcceptSharePatientWithHospitalSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof acceptSharePatientWithHospital>>,
  TError = unknown,
>(
  params: AcceptSharePatientWithHospitalParams,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof acceptSharePatientWithHospital>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getAcceptSharePatientWithHospitalQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof acceptSharePatientWithHospital>>> = ({ signal }) =>
    acceptSharePatientWithHospital(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof acceptSharePatientWithHospital>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type AcceptSharePatientWithHospitalSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof acceptSharePatientWithHospital>>
>;
export type AcceptSharePatientWithHospitalSuspenseQueryError = unknown;

export function useAcceptSharePatientWithHospitalSuspense<
  TData = Awaited<ReturnType<typeof acceptSharePatientWithHospital>>,
  TError = unknown,
>(
  params: AcceptSharePatientWithHospitalParams,
  options: {
    query: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof acceptSharePatientWithHospital>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useAcceptSharePatientWithHospitalSuspense<
  TData = Awaited<ReturnType<typeof acceptSharePatientWithHospital>>,
  TError = unknown,
>(
  params: AcceptSharePatientWithHospitalParams,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof acceptSharePatientWithHospital>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useAcceptSharePatientWithHospitalSuspense<
  TData = Awaited<ReturnType<typeof acceptSharePatientWithHospital>>,
  TError = unknown,
>(
  params: AcceptSharePatientWithHospitalParams,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof acceptSharePatientWithHospital>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useAcceptSharePatientWithHospitalSuspense<
  TData = Awaited<ReturnType<typeof acceptSharePatientWithHospital>>,
  TError = unknown,
>(
  params: AcceptSharePatientWithHospitalParams,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof acceptSharePatientWithHospital>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getAcceptSharePatientWithHospitalSuspenseQueryOptions(params, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getHeurekaPatientByPid = (
  practiceId: string,
  params: GetHeurekaPatientByPidParams,
  options?: SecondParameter<typeof request>,
  signal?: AbortSignal,
) => {
  return request<HeurekaPatientDto>(
    { url: `/heureka/practices/${practiceId}/patients`, method: 'GET', params, signal },
    options,
  );
};

export const getGetHeurekaPatientByPidQueryKey = (practiceId: string, params: GetHeurekaPatientByPidParams) => {
  return [`/heureka/practices/${practiceId}/patients`, ...(params ? [params] : [])] as const;
};

export const getGetHeurekaPatientByPidQueryOptions = <
  TData = Awaited<ReturnType<typeof getHeurekaPatientByPid>>,
  TError = unknown,
>(
  practiceId: string,
  params: GetHeurekaPatientByPidParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getHeurekaPatientByPid>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetHeurekaPatientByPidQueryKey(practiceId, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getHeurekaPatientByPid>>> = ({ signal }) =>
    getHeurekaPatientByPid(practiceId, params, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!practiceId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getHeurekaPatientByPid>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetHeurekaPatientByPidQueryResult = NonNullable<Awaited<ReturnType<typeof getHeurekaPatientByPid>>>;
export type GetHeurekaPatientByPidQueryError = unknown;

export function useGetHeurekaPatientByPid<TData = Awaited<ReturnType<typeof getHeurekaPatientByPid>>, TError = unknown>(
  practiceId: string,
  params: GetHeurekaPatientByPidParams,
  options: {
    query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getHeurekaPatientByPid>>, TError, TData>> &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getHeurekaPatientByPid>>,
          TError,
          Awaited<ReturnType<typeof getHeurekaPatientByPid>>
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof request>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetHeurekaPatientByPid<TData = Awaited<ReturnType<typeof getHeurekaPatientByPid>>, TError = unknown>(
  practiceId: string,
  params: GetHeurekaPatientByPidParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getHeurekaPatientByPid>>, TError, TData>> &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getHeurekaPatientByPid>>,
          TError,
          Awaited<ReturnType<typeof getHeurekaPatientByPid>>
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof request>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetHeurekaPatientByPid<TData = Awaited<ReturnType<typeof getHeurekaPatientByPid>>, TError = unknown>(
  practiceId: string,
  params: GetHeurekaPatientByPidParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getHeurekaPatientByPid>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useGetHeurekaPatientByPid<TData = Awaited<ReturnType<typeof getHeurekaPatientByPid>>, TError = unknown>(
  practiceId: string,
  params: GetHeurekaPatientByPidParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getHeurekaPatientByPid>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetHeurekaPatientByPidQueryOptions(practiceId, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getGetHeurekaPatientByPidSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getHeurekaPatientByPid>>,
  TError = unknown,
>(
  practiceId: string,
  params: GetHeurekaPatientByPidParams,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getHeurekaPatientByPid>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetHeurekaPatientByPidQueryKey(practiceId, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getHeurekaPatientByPid>>> = ({ signal }) =>
    getHeurekaPatientByPid(practiceId, params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getHeurekaPatientByPid>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetHeurekaPatientByPidSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getHeurekaPatientByPid>>>;
export type GetHeurekaPatientByPidSuspenseQueryError = unknown;

export function useGetHeurekaPatientByPidSuspense<
  TData = Awaited<ReturnType<typeof getHeurekaPatientByPid>>,
  TError = unknown,
>(
  practiceId: string,
  params: GetHeurekaPatientByPidParams,
  options: {
    query: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getHeurekaPatientByPid>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetHeurekaPatientByPidSuspense<
  TData = Awaited<ReturnType<typeof getHeurekaPatientByPid>>,
  TError = unknown,
>(
  practiceId: string,
  params: GetHeurekaPatientByPidParams,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getHeurekaPatientByPid>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetHeurekaPatientByPidSuspense<
  TData = Awaited<ReturnType<typeof getHeurekaPatientByPid>>,
  TError = unknown,
>(
  practiceId: string,
  params: GetHeurekaPatientByPidParams,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getHeurekaPatientByPid>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useGetHeurekaPatientByPidSuspense<
  TData = Awaited<ReturnType<typeof getHeurekaPatientByPid>>,
  TError = unknown,
>(
  practiceId: string,
  params: GetHeurekaPatientByPidParams,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getHeurekaPatientByPid>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetHeurekaPatientByPidSuspenseQueryOptions(practiceId, params, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const isHeurekaConnected = (
  practiceId: string,
  options?: SecondParameter<typeof request>,
  signal?: AbortSignal,
) => {
  return request<HeurekaDto>(
    { url: `/heureka/practices/${practiceId}/connectionstate`, method: 'GET', signal },
    options,
  );
};

export const getIsHeurekaConnectedQueryKey = (practiceId: string) => {
  return [`/heureka/practices/${practiceId}/connectionstate`] as const;
};

export const getIsHeurekaConnectedQueryOptions = <
  TData = Awaited<ReturnType<typeof isHeurekaConnected>>,
  TError = unknown,
>(
  practiceId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof isHeurekaConnected>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getIsHeurekaConnectedQueryKey(practiceId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof isHeurekaConnected>>> = ({ signal }) =>
    isHeurekaConnected(practiceId, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!practiceId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof isHeurekaConnected>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type IsHeurekaConnectedQueryResult = NonNullable<Awaited<ReturnType<typeof isHeurekaConnected>>>;
export type IsHeurekaConnectedQueryError = unknown;

export function useIsHeurekaConnected<TData = Awaited<ReturnType<typeof isHeurekaConnected>>, TError = unknown>(
  practiceId: string,
  options: {
    query: Partial<UseQueryOptions<Awaited<ReturnType<typeof isHeurekaConnected>>, TError, TData>> &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof isHeurekaConnected>>,
          TError,
          Awaited<ReturnType<typeof isHeurekaConnected>>
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof request>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useIsHeurekaConnected<TData = Awaited<ReturnType<typeof isHeurekaConnected>>, TError = unknown>(
  practiceId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof isHeurekaConnected>>, TError, TData>> &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof isHeurekaConnected>>,
          TError,
          Awaited<ReturnType<typeof isHeurekaConnected>>
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof request>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useIsHeurekaConnected<TData = Awaited<ReturnType<typeof isHeurekaConnected>>, TError = unknown>(
  practiceId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof isHeurekaConnected>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useIsHeurekaConnected<TData = Awaited<ReturnType<typeof isHeurekaConnected>>, TError = unknown>(
  practiceId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof isHeurekaConnected>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getIsHeurekaConnectedQueryOptions(practiceId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getIsHeurekaConnectedSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof isHeurekaConnected>>,
  TError = unknown,
>(
  practiceId: string,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof isHeurekaConnected>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getIsHeurekaConnectedQueryKey(practiceId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof isHeurekaConnected>>> = ({ signal }) =>
    isHeurekaConnected(practiceId, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof isHeurekaConnected>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type IsHeurekaConnectedSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof isHeurekaConnected>>>;
export type IsHeurekaConnectedSuspenseQueryError = unknown;

export function useIsHeurekaConnectedSuspense<TData = Awaited<ReturnType<typeof isHeurekaConnected>>, TError = unknown>(
  practiceId: string,
  options: {
    query: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof isHeurekaConnected>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useIsHeurekaConnectedSuspense<TData = Awaited<ReturnType<typeof isHeurekaConnected>>, TError = unknown>(
  practiceId: string,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof isHeurekaConnected>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useIsHeurekaConnectedSuspense<TData = Awaited<ReturnType<typeof isHeurekaConnected>>, TError = unknown>(
  practiceId: string,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof isHeurekaConnected>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useIsHeurekaConnectedSuspense<TData = Awaited<ReturnType<typeof isHeurekaConnected>>, TError = unknown>(
  practiceId: string,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof isHeurekaConnected>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getIsHeurekaConnectedSuspenseQueryOptions(practiceId, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getHeurekaPatient = (
  patientId: string,
  options?: SecondParameter<typeof request>,
  signal?: AbortSignal,
) => {
  return request<HeurekaPatientDetailsDto>({ url: `/heureka/patients/${patientId}`, method: 'GET', signal }, options);
};

export const getGetHeurekaPatientQueryKey = (patientId: string) => {
  return [`/heureka/patients/${patientId}`] as const;
};

export const getGetHeurekaPatientQueryOptions = <
  TData = Awaited<ReturnType<typeof getHeurekaPatient>>,
  TError = unknown,
>(
  patientId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getHeurekaPatient>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetHeurekaPatientQueryKey(patientId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getHeurekaPatient>>> = ({ signal }) =>
    getHeurekaPatient(patientId, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!patientId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getHeurekaPatient>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetHeurekaPatientQueryResult = NonNullable<Awaited<ReturnType<typeof getHeurekaPatient>>>;
export type GetHeurekaPatientQueryError = unknown;

export function useGetHeurekaPatient<TData = Awaited<ReturnType<typeof getHeurekaPatient>>, TError = unknown>(
  patientId: string,
  options: {
    query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getHeurekaPatient>>, TError, TData>> &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getHeurekaPatient>>,
          TError,
          Awaited<ReturnType<typeof getHeurekaPatient>>
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof request>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetHeurekaPatient<TData = Awaited<ReturnType<typeof getHeurekaPatient>>, TError = unknown>(
  patientId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getHeurekaPatient>>, TError, TData>> &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getHeurekaPatient>>,
          TError,
          Awaited<ReturnType<typeof getHeurekaPatient>>
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof request>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetHeurekaPatient<TData = Awaited<ReturnType<typeof getHeurekaPatient>>, TError = unknown>(
  patientId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getHeurekaPatient>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useGetHeurekaPatient<TData = Awaited<ReturnType<typeof getHeurekaPatient>>, TError = unknown>(
  patientId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getHeurekaPatient>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetHeurekaPatientQueryOptions(patientId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getGetHeurekaPatientSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getHeurekaPatient>>,
  TError = unknown,
>(
  patientId: string,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getHeurekaPatient>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetHeurekaPatientQueryKey(patientId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getHeurekaPatient>>> = ({ signal }) =>
    getHeurekaPatient(patientId, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getHeurekaPatient>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetHeurekaPatientSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getHeurekaPatient>>>;
export type GetHeurekaPatientSuspenseQueryError = unknown;

export function useGetHeurekaPatientSuspense<TData = Awaited<ReturnType<typeof getHeurekaPatient>>, TError = unknown>(
  patientId: string,
  options: {
    query: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getHeurekaPatient>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetHeurekaPatientSuspense<TData = Awaited<ReturnType<typeof getHeurekaPatient>>, TError = unknown>(
  patientId: string,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getHeurekaPatient>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetHeurekaPatientSuspense<TData = Awaited<ReturnType<typeof getHeurekaPatient>>, TError = unknown>(
  patientId: string,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getHeurekaPatient>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useGetHeurekaPatientSuspense<TData = Awaited<ReturnType<typeof getHeurekaPatient>>, TError = unknown>(
  patientId: string,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getHeurekaPatient>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetHeurekaPatientSuspenseQueryOptions(patientId, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getActiveEmergencyServiceForEmergencyCircle = (
  params: GetActiveEmergencyServiceForEmergencyCircleParams,
  options?: SecondParameter<typeof request>,
  signal?: AbortSignal,
) => {
  return request<EmergencyServiceDto>({ url: `/emergency-service`, method: 'GET', params, signal }, options);
};

export const getGetActiveEmergencyServiceForEmergencyCircleQueryKey = (
  params: GetActiveEmergencyServiceForEmergencyCircleParams,
) => {
  return [`/emergency-service`, ...(params ? [params] : [])] as const;
};

export const getGetActiveEmergencyServiceForEmergencyCircleQueryOptions = <
  TData = Awaited<ReturnType<typeof getActiveEmergencyServiceForEmergencyCircle>>,
  TError = unknown,
>(
  params: GetActiveEmergencyServiceForEmergencyCircleParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getActiveEmergencyServiceForEmergencyCircle>>, TError, TData>
    >;
    request?: SecondParameter<typeof request>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetActiveEmergencyServiceForEmergencyCircleQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getActiveEmergencyServiceForEmergencyCircle>>> = ({
    signal,
  }) => getActiveEmergencyServiceForEmergencyCircle(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getActiveEmergencyServiceForEmergencyCircle>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetActiveEmergencyServiceForEmergencyCircleQueryResult = NonNullable<
  Awaited<ReturnType<typeof getActiveEmergencyServiceForEmergencyCircle>>
>;
export type GetActiveEmergencyServiceForEmergencyCircleQueryError = unknown;

export function useGetActiveEmergencyServiceForEmergencyCircle<
  TData = Awaited<ReturnType<typeof getActiveEmergencyServiceForEmergencyCircle>>,
  TError = unknown,
>(
  params: GetActiveEmergencyServiceForEmergencyCircleParams,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getActiveEmergencyServiceForEmergencyCircle>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getActiveEmergencyServiceForEmergencyCircle>>,
          TError,
          Awaited<ReturnType<typeof getActiveEmergencyServiceForEmergencyCircle>>
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof request>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetActiveEmergencyServiceForEmergencyCircle<
  TData = Awaited<ReturnType<typeof getActiveEmergencyServiceForEmergencyCircle>>,
  TError = unknown,
>(
  params: GetActiveEmergencyServiceForEmergencyCircleParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getActiveEmergencyServiceForEmergencyCircle>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getActiveEmergencyServiceForEmergencyCircle>>,
          TError,
          Awaited<ReturnType<typeof getActiveEmergencyServiceForEmergencyCircle>>
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof request>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetActiveEmergencyServiceForEmergencyCircle<
  TData = Awaited<ReturnType<typeof getActiveEmergencyServiceForEmergencyCircle>>,
  TError = unknown,
>(
  params: GetActiveEmergencyServiceForEmergencyCircleParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getActiveEmergencyServiceForEmergencyCircle>>, TError, TData>
    >;
    request?: SecondParameter<typeof request>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useGetActiveEmergencyServiceForEmergencyCircle<
  TData = Awaited<ReturnType<typeof getActiveEmergencyServiceForEmergencyCircle>>,
  TError = unknown,
>(
  params: GetActiveEmergencyServiceForEmergencyCircleParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getActiveEmergencyServiceForEmergencyCircle>>, TError, TData>
    >;
    request?: SecondParameter<typeof request>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetActiveEmergencyServiceForEmergencyCircleQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getGetActiveEmergencyServiceForEmergencyCircleSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getActiveEmergencyServiceForEmergencyCircle>>,
  TError = unknown,
>(
  params: GetActiveEmergencyServiceForEmergencyCircleParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof getActiveEmergencyServiceForEmergencyCircle>>, TError, TData>
    >;
    request?: SecondParameter<typeof request>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetActiveEmergencyServiceForEmergencyCircleQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getActiveEmergencyServiceForEmergencyCircle>>> = ({
    signal,
  }) => getActiveEmergencyServiceForEmergencyCircle(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getActiveEmergencyServiceForEmergencyCircle>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetActiveEmergencyServiceForEmergencyCircleSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof getActiveEmergencyServiceForEmergencyCircle>>
>;
export type GetActiveEmergencyServiceForEmergencyCircleSuspenseQueryError = unknown;

export function useGetActiveEmergencyServiceForEmergencyCircleSuspense<
  TData = Awaited<ReturnType<typeof getActiveEmergencyServiceForEmergencyCircle>>,
  TError = unknown,
>(
  params: GetActiveEmergencyServiceForEmergencyCircleParams,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof getActiveEmergencyServiceForEmergencyCircle>>, TError, TData>
    >;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetActiveEmergencyServiceForEmergencyCircleSuspense<
  TData = Awaited<ReturnType<typeof getActiveEmergencyServiceForEmergencyCircle>>,
  TError = unknown,
>(
  params: GetActiveEmergencyServiceForEmergencyCircleParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof getActiveEmergencyServiceForEmergencyCircle>>, TError, TData>
    >;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetActiveEmergencyServiceForEmergencyCircleSuspense<
  TData = Awaited<ReturnType<typeof getActiveEmergencyServiceForEmergencyCircle>>,
  TError = unknown,
>(
  params: GetActiveEmergencyServiceForEmergencyCircleParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof getActiveEmergencyServiceForEmergencyCircle>>, TError, TData>
    >;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useGetActiveEmergencyServiceForEmergencyCircleSuspense<
  TData = Awaited<ReturnType<typeof getActiveEmergencyServiceForEmergencyCircle>>,
  TError = unknown,
>(
  params: GetActiveEmergencyServiceForEmergencyCircleParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof getActiveEmergencyServiceForEmergencyCircle>>, TError, TData>
    >;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetActiveEmergencyServiceForEmergencyCircleSuspenseQueryOptions(params, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getActiveEmergencyServicesForPractice = (
  practiceId: string,
  options?: SecondParameter<typeof request>,
  signal?: AbortSignal,
) => {
  return request<EmergencyServiceDto[]>(
    { url: `/emergency-service/practice/${practiceId}`, method: 'GET', signal },
    options,
  );
};

export const getGetActiveEmergencyServicesForPracticeQueryKey = (practiceId: string) => {
  return [`/emergency-service/practice/${practiceId}`] as const;
};

export const getGetActiveEmergencyServicesForPracticeQueryOptions = <
  TData = Awaited<ReturnType<typeof getActiveEmergencyServicesForPractice>>,
  TError = unknown,
>(
  practiceId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getActiveEmergencyServicesForPractice>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetActiveEmergencyServicesForPracticeQueryKey(practiceId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getActiveEmergencyServicesForPractice>>> = ({ signal }) =>
    getActiveEmergencyServicesForPractice(practiceId, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!practiceId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getActiveEmergencyServicesForPractice>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetActiveEmergencyServicesForPracticeQueryResult = NonNullable<
  Awaited<ReturnType<typeof getActiveEmergencyServicesForPractice>>
>;
export type GetActiveEmergencyServicesForPracticeQueryError = unknown;

export function useGetActiveEmergencyServicesForPractice<
  TData = Awaited<ReturnType<typeof getActiveEmergencyServicesForPractice>>,
  TError = unknown,
>(
  practiceId: string,
  options: {
    query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getActiveEmergencyServicesForPractice>>, TError, TData>> &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getActiveEmergencyServicesForPractice>>,
          TError,
          Awaited<ReturnType<typeof getActiveEmergencyServicesForPractice>>
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof request>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetActiveEmergencyServicesForPractice<
  TData = Awaited<ReturnType<typeof getActiveEmergencyServicesForPractice>>,
  TError = unknown,
>(
  practiceId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getActiveEmergencyServicesForPractice>>, TError, TData>> &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getActiveEmergencyServicesForPractice>>,
          TError,
          Awaited<ReturnType<typeof getActiveEmergencyServicesForPractice>>
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof request>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetActiveEmergencyServicesForPractice<
  TData = Awaited<ReturnType<typeof getActiveEmergencyServicesForPractice>>,
  TError = unknown,
>(
  practiceId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getActiveEmergencyServicesForPractice>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useGetActiveEmergencyServicesForPractice<
  TData = Awaited<ReturnType<typeof getActiveEmergencyServicesForPractice>>,
  TError = unknown,
>(
  practiceId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getActiveEmergencyServicesForPractice>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetActiveEmergencyServicesForPracticeQueryOptions(practiceId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getGetActiveEmergencyServicesForPracticeSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getActiveEmergencyServicesForPractice>>,
  TError = unknown,
>(
  practiceId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof getActiveEmergencyServicesForPractice>>, TError, TData>
    >;
    request?: SecondParameter<typeof request>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetActiveEmergencyServicesForPracticeQueryKey(practiceId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getActiveEmergencyServicesForPractice>>> = ({ signal }) =>
    getActiveEmergencyServicesForPractice(practiceId, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getActiveEmergencyServicesForPractice>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetActiveEmergencyServicesForPracticeSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof getActiveEmergencyServicesForPractice>>
>;
export type GetActiveEmergencyServicesForPracticeSuspenseQueryError = unknown;

export function useGetActiveEmergencyServicesForPracticeSuspense<
  TData = Awaited<ReturnType<typeof getActiveEmergencyServicesForPractice>>,
  TError = unknown,
>(
  practiceId: string,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof getActiveEmergencyServicesForPractice>>, TError, TData>
    >;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetActiveEmergencyServicesForPracticeSuspense<
  TData = Awaited<ReturnType<typeof getActiveEmergencyServicesForPractice>>,
  TError = unknown,
>(
  practiceId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof getActiveEmergencyServicesForPractice>>, TError, TData>
    >;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetActiveEmergencyServicesForPracticeSuspense<
  TData = Awaited<ReturnType<typeof getActiveEmergencyServicesForPractice>>,
  TError = unknown,
>(
  practiceId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof getActiveEmergencyServicesForPractice>>, TError, TData>
    >;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useGetActiveEmergencyServicesForPracticeSuspense<
  TData = Awaited<ReturnType<typeof getActiveEmergencyServicesForPractice>>,
  TError = unknown,
>(
  practiceId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof getActiveEmergencyServicesForPractice>>, TError, TData>
    >;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetActiveEmergencyServicesForPracticeSuspenseQueryOptions(practiceId, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getEmergencyCirclesForPractice = (
  params: GetEmergencyCirclesForPracticeParams,
  options?: SecondParameter<typeof request>,
  signal?: AbortSignal,
) => {
  return request<EmergencyCircleDto[]>({ url: `/emergency-circle`, method: 'GET', params, signal }, options);
};

export const getGetEmergencyCirclesForPracticeQueryKey = (params: GetEmergencyCirclesForPracticeParams) => {
  return [`/emergency-circle`, ...(params ? [params] : [])] as const;
};

export const getGetEmergencyCirclesForPracticeQueryOptions = <
  TData = Awaited<ReturnType<typeof getEmergencyCirclesForPractice>>,
  TError = unknown,
>(
  params: GetEmergencyCirclesForPracticeParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getEmergencyCirclesForPractice>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetEmergencyCirclesForPracticeQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getEmergencyCirclesForPractice>>> = ({ signal }) =>
    getEmergencyCirclesForPractice(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getEmergencyCirclesForPractice>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetEmergencyCirclesForPracticeQueryResult = NonNullable<
  Awaited<ReturnType<typeof getEmergencyCirclesForPractice>>
>;
export type GetEmergencyCirclesForPracticeQueryError = unknown;

export function useGetEmergencyCirclesForPractice<
  TData = Awaited<ReturnType<typeof getEmergencyCirclesForPractice>>,
  TError = unknown,
>(
  params: GetEmergencyCirclesForPracticeParams,
  options: {
    query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getEmergencyCirclesForPractice>>, TError, TData>> &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getEmergencyCirclesForPractice>>,
          TError,
          Awaited<ReturnType<typeof getEmergencyCirclesForPractice>>
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof request>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetEmergencyCirclesForPractice<
  TData = Awaited<ReturnType<typeof getEmergencyCirclesForPractice>>,
  TError = unknown,
>(
  params: GetEmergencyCirclesForPracticeParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getEmergencyCirclesForPractice>>, TError, TData>> &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getEmergencyCirclesForPractice>>,
          TError,
          Awaited<ReturnType<typeof getEmergencyCirclesForPractice>>
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof request>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetEmergencyCirclesForPractice<
  TData = Awaited<ReturnType<typeof getEmergencyCirclesForPractice>>,
  TError = unknown,
>(
  params: GetEmergencyCirclesForPracticeParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getEmergencyCirclesForPractice>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useGetEmergencyCirclesForPractice<
  TData = Awaited<ReturnType<typeof getEmergencyCirclesForPractice>>,
  TError = unknown,
>(
  params: GetEmergencyCirclesForPracticeParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getEmergencyCirclesForPractice>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetEmergencyCirclesForPracticeQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getGetEmergencyCirclesForPracticeSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getEmergencyCirclesForPractice>>,
  TError = unknown,
>(
  params: GetEmergencyCirclesForPracticeParams,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getEmergencyCirclesForPractice>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetEmergencyCirclesForPracticeQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getEmergencyCirclesForPractice>>> = ({ signal }) =>
    getEmergencyCirclesForPractice(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getEmergencyCirclesForPractice>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetEmergencyCirclesForPracticeSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof getEmergencyCirclesForPractice>>
>;
export type GetEmergencyCirclesForPracticeSuspenseQueryError = unknown;

export function useGetEmergencyCirclesForPracticeSuspense<
  TData = Awaited<ReturnType<typeof getEmergencyCirclesForPractice>>,
  TError = unknown,
>(
  params: GetEmergencyCirclesForPracticeParams,
  options: {
    query: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getEmergencyCirclesForPractice>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetEmergencyCirclesForPracticeSuspense<
  TData = Awaited<ReturnType<typeof getEmergencyCirclesForPractice>>,
  TError = unknown,
>(
  params: GetEmergencyCirclesForPracticeParams,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getEmergencyCirclesForPractice>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetEmergencyCirclesForPracticeSuspense<
  TData = Awaited<ReturnType<typeof getEmergencyCirclesForPractice>>,
  TError = unknown,
>(
  params: GetEmergencyCirclesForPracticeParams,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getEmergencyCirclesForPractice>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useGetEmergencyCirclesForPracticeSuspense<
  TData = Awaited<ReturnType<typeof getEmergencyCirclesForPractice>>,
  TError = unknown,
>(
  params: GetEmergencyCirclesForPracticeParams,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getEmergencyCirclesForPractice>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetEmergencyCirclesForPracticeSuspenseQueryOptions(params, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getOfferedDeputyForDeputyPractice = (
  id: string,
  options?: SecondParameter<typeof request>,
  signal?: AbortSignal,
) => {
  return request<DeputyDto>({ url: `/deputies/${id}`, method: 'GET', signal }, options);
};

export const getGetOfferedDeputyForDeputyPracticeQueryKey = (id: string) => {
  return [`/deputies/${id}`] as const;
};

export const getGetOfferedDeputyForDeputyPracticeQueryOptions = <
  TData = Awaited<ReturnType<typeof getOfferedDeputyForDeputyPractice>>,
  TError = unknown,
>(
  id: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOfferedDeputyForDeputyPractice>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetOfferedDeputyForDeputyPracticeQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getOfferedDeputyForDeputyPractice>>> = ({ signal }) =>
    getOfferedDeputyForDeputyPractice(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getOfferedDeputyForDeputyPractice>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetOfferedDeputyForDeputyPracticeQueryResult = NonNullable<
  Awaited<ReturnType<typeof getOfferedDeputyForDeputyPractice>>
>;
export type GetOfferedDeputyForDeputyPracticeQueryError = unknown;

export function useGetOfferedDeputyForDeputyPractice<
  TData = Awaited<ReturnType<typeof getOfferedDeputyForDeputyPractice>>,
  TError = unknown,
>(
  id: string,
  options: {
    query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOfferedDeputyForDeputyPractice>>, TError, TData>> &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getOfferedDeputyForDeputyPractice>>,
          TError,
          Awaited<ReturnType<typeof getOfferedDeputyForDeputyPractice>>
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof request>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetOfferedDeputyForDeputyPractice<
  TData = Awaited<ReturnType<typeof getOfferedDeputyForDeputyPractice>>,
  TError = unknown,
>(
  id: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOfferedDeputyForDeputyPractice>>, TError, TData>> &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getOfferedDeputyForDeputyPractice>>,
          TError,
          Awaited<ReturnType<typeof getOfferedDeputyForDeputyPractice>>
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof request>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetOfferedDeputyForDeputyPractice<
  TData = Awaited<ReturnType<typeof getOfferedDeputyForDeputyPractice>>,
  TError = unknown,
>(
  id: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOfferedDeputyForDeputyPractice>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useGetOfferedDeputyForDeputyPractice<
  TData = Awaited<ReturnType<typeof getOfferedDeputyForDeputyPractice>>,
  TError = unknown,
>(
  id: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getOfferedDeputyForDeputyPractice>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetOfferedDeputyForDeputyPracticeQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getGetOfferedDeputyForDeputyPracticeSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getOfferedDeputyForDeputyPractice>>,
  TError = unknown,
>(
  id: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof getOfferedDeputyForDeputyPractice>>, TError, TData>
    >;
    request?: SecondParameter<typeof request>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetOfferedDeputyForDeputyPracticeQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getOfferedDeputyForDeputyPractice>>> = ({ signal }) =>
    getOfferedDeputyForDeputyPractice(id, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getOfferedDeputyForDeputyPractice>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetOfferedDeputyForDeputyPracticeSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof getOfferedDeputyForDeputyPractice>>
>;
export type GetOfferedDeputyForDeputyPracticeSuspenseQueryError = unknown;

export function useGetOfferedDeputyForDeputyPracticeSuspense<
  TData = Awaited<ReturnType<typeof getOfferedDeputyForDeputyPractice>>,
  TError = unknown,
>(
  id: string,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof getOfferedDeputyForDeputyPractice>>, TError, TData>
    >;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetOfferedDeputyForDeputyPracticeSuspense<
  TData = Awaited<ReturnType<typeof getOfferedDeputyForDeputyPractice>>,
  TError = unknown,
>(
  id: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof getOfferedDeputyForDeputyPractice>>, TError, TData>
    >;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetOfferedDeputyForDeputyPracticeSuspense<
  TData = Awaited<ReturnType<typeof getOfferedDeputyForDeputyPractice>>,
  TError = unknown,
>(
  id: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof getOfferedDeputyForDeputyPractice>>, TError, TData>
    >;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useGetOfferedDeputyForDeputyPracticeSuspense<
  TData = Awaited<ReturnType<typeof getOfferedDeputyForDeputyPractice>>,
  TError = unknown,
>(
  id: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof getOfferedDeputyForDeputyPractice>>, TError, TData>
    >;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetOfferedDeputyForDeputyPracticeSuspenseQueryOptions(id, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getUnexpiredNonRevokedDeputiesForSharedPractice = (
  params: GetUnexpiredNonRevokedDeputiesForSharedPracticeParams,
  options?: SecondParameter<typeof request>,
  signal?: AbortSignal,
) => {
  return request<DeputyDto[]>({ url: `/deputies/unexpirednonrevoked`, method: 'GET', params, signal }, options);
};

export const getGetUnexpiredNonRevokedDeputiesForSharedPracticeQueryKey = (
  params: GetUnexpiredNonRevokedDeputiesForSharedPracticeParams,
) => {
  return [`/deputies/unexpirednonrevoked`, ...(params ? [params] : [])] as const;
};

export const getGetUnexpiredNonRevokedDeputiesForSharedPracticeQueryOptions = <
  TData = Awaited<ReturnType<typeof getUnexpiredNonRevokedDeputiesForSharedPractice>>,
  TError = unknown,
>(
  params: GetUnexpiredNonRevokedDeputiesForSharedPracticeParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getUnexpiredNonRevokedDeputiesForSharedPractice>>, TError, TData>
    >;
    request?: SecondParameter<typeof request>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUnexpiredNonRevokedDeputiesForSharedPracticeQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUnexpiredNonRevokedDeputiesForSharedPractice>>> = ({
    signal,
  }) => getUnexpiredNonRevokedDeputiesForSharedPractice(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getUnexpiredNonRevokedDeputiesForSharedPractice>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetUnexpiredNonRevokedDeputiesForSharedPracticeQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUnexpiredNonRevokedDeputiesForSharedPractice>>
>;
export type GetUnexpiredNonRevokedDeputiesForSharedPracticeQueryError = unknown;

export function useGetUnexpiredNonRevokedDeputiesForSharedPractice<
  TData = Awaited<ReturnType<typeof getUnexpiredNonRevokedDeputiesForSharedPractice>>,
  TError = unknown,
>(
  params: GetUnexpiredNonRevokedDeputiesForSharedPracticeParams,
  options: {
    query: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getUnexpiredNonRevokedDeputiesForSharedPractice>>, TError, TData>
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getUnexpiredNonRevokedDeputiesForSharedPractice>>,
          TError,
          Awaited<ReturnType<typeof getUnexpiredNonRevokedDeputiesForSharedPractice>>
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof request>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetUnexpiredNonRevokedDeputiesForSharedPractice<
  TData = Awaited<ReturnType<typeof getUnexpiredNonRevokedDeputiesForSharedPractice>>,
  TError = unknown,
>(
  params: GetUnexpiredNonRevokedDeputiesForSharedPracticeParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getUnexpiredNonRevokedDeputiesForSharedPractice>>, TError, TData>
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getUnexpiredNonRevokedDeputiesForSharedPractice>>,
          TError,
          Awaited<ReturnType<typeof getUnexpiredNonRevokedDeputiesForSharedPractice>>
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof request>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetUnexpiredNonRevokedDeputiesForSharedPractice<
  TData = Awaited<ReturnType<typeof getUnexpiredNonRevokedDeputiesForSharedPractice>>,
  TError = unknown,
>(
  params: GetUnexpiredNonRevokedDeputiesForSharedPracticeParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getUnexpiredNonRevokedDeputiesForSharedPractice>>, TError, TData>
    >;
    request?: SecondParameter<typeof request>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useGetUnexpiredNonRevokedDeputiesForSharedPractice<
  TData = Awaited<ReturnType<typeof getUnexpiredNonRevokedDeputiesForSharedPractice>>,
  TError = unknown,
>(
  params: GetUnexpiredNonRevokedDeputiesForSharedPracticeParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getUnexpiredNonRevokedDeputiesForSharedPractice>>, TError, TData>
    >;
    request?: SecondParameter<typeof request>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetUnexpiredNonRevokedDeputiesForSharedPracticeQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getGetUnexpiredNonRevokedDeputiesForSharedPracticeSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getUnexpiredNonRevokedDeputiesForSharedPractice>>,
  TError = unknown,
>(
  params: GetUnexpiredNonRevokedDeputiesForSharedPracticeParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getUnexpiredNonRevokedDeputiesForSharedPractice>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof request>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUnexpiredNonRevokedDeputiesForSharedPracticeQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUnexpiredNonRevokedDeputiesForSharedPractice>>> = ({
    signal,
  }) => getUnexpiredNonRevokedDeputiesForSharedPractice(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getUnexpiredNonRevokedDeputiesForSharedPractice>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetUnexpiredNonRevokedDeputiesForSharedPracticeSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUnexpiredNonRevokedDeputiesForSharedPractice>>
>;
export type GetUnexpiredNonRevokedDeputiesForSharedPracticeSuspenseQueryError = unknown;

export function useGetUnexpiredNonRevokedDeputiesForSharedPracticeSuspense<
  TData = Awaited<ReturnType<typeof getUnexpiredNonRevokedDeputiesForSharedPractice>>,
  TError = unknown,
>(
  params: GetUnexpiredNonRevokedDeputiesForSharedPracticeParams,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getUnexpiredNonRevokedDeputiesForSharedPractice>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetUnexpiredNonRevokedDeputiesForSharedPracticeSuspense<
  TData = Awaited<ReturnType<typeof getUnexpiredNonRevokedDeputiesForSharedPractice>>,
  TError = unknown,
>(
  params: GetUnexpiredNonRevokedDeputiesForSharedPracticeParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getUnexpiredNonRevokedDeputiesForSharedPractice>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetUnexpiredNonRevokedDeputiesForSharedPracticeSuspense<
  TData = Awaited<ReturnType<typeof getUnexpiredNonRevokedDeputiesForSharedPractice>>,
  TError = unknown,
>(
  params: GetUnexpiredNonRevokedDeputiesForSharedPracticeParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getUnexpiredNonRevokedDeputiesForSharedPractice>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useGetUnexpiredNonRevokedDeputiesForSharedPracticeSuspense<
  TData = Awaited<ReturnType<typeof getUnexpiredNonRevokedDeputiesForSharedPractice>>,
  TError = unknown,
>(
  params: GetUnexpiredNonRevokedDeputiesForSharedPracticeParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof getUnexpiredNonRevokedDeputiesForSharedPractice>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetUnexpiredNonRevokedDeputiesForSharedPracticeSuspenseQueryOptions(params, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getDeputyPracticesByName = (
  params: GetDeputyPracticesByNameParams,
  options?: SecondParameter<typeof request>,
  signal?: AbortSignal,
) => {
  return request<PracticeDto[]>({ url: `/deputies/search`, method: 'GET', params, signal }, options);
};

export const getGetDeputyPracticesByNameQueryKey = (params: GetDeputyPracticesByNameParams) => {
  return [`/deputies/search`, ...(params ? [params] : [])] as const;
};

export const getGetDeputyPracticesByNameQueryOptions = <
  TData = Awaited<ReturnType<typeof getDeputyPracticesByName>>,
  TError = unknown,
>(
  params: GetDeputyPracticesByNameParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getDeputyPracticesByName>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetDeputyPracticesByNameQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getDeputyPracticesByName>>> = ({ signal }) =>
    getDeputyPracticesByName(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getDeputyPracticesByName>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetDeputyPracticesByNameQueryResult = NonNullable<Awaited<ReturnType<typeof getDeputyPracticesByName>>>;
export type GetDeputyPracticesByNameQueryError = unknown;

export function useGetDeputyPracticesByName<
  TData = Awaited<ReturnType<typeof getDeputyPracticesByName>>,
  TError = unknown,
>(
  params: GetDeputyPracticesByNameParams,
  options: {
    query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getDeputyPracticesByName>>, TError, TData>> &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getDeputyPracticesByName>>,
          TError,
          Awaited<ReturnType<typeof getDeputyPracticesByName>>
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof request>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetDeputyPracticesByName<
  TData = Awaited<ReturnType<typeof getDeputyPracticesByName>>,
  TError = unknown,
>(
  params: GetDeputyPracticesByNameParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getDeputyPracticesByName>>, TError, TData>> &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getDeputyPracticesByName>>,
          TError,
          Awaited<ReturnType<typeof getDeputyPracticesByName>>
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof request>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetDeputyPracticesByName<
  TData = Awaited<ReturnType<typeof getDeputyPracticesByName>>,
  TError = unknown,
>(
  params: GetDeputyPracticesByNameParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getDeputyPracticesByName>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useGetDeputyPracticesByName<
  TData = Awaited<ReturnType<typeof getDeputyPracticesByName>>,
  TError = unknown,
>(
  params: GetDeputyPracticesByNameParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getDeputyPracticesByName>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetDeputyPracticesByNameQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getGetDeputyPracticesByNameSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getDeputyPracticesByName>>,
  TError = unknown,
>(
  params: GetDeputyPracticesByNameParams,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getDeputyPracticesByName>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetDeputyPracticesByNameQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getDeputyPracticesByName>>> = ({ signal }) =>
    getDeputyPracticesByName(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getDeputyPracticesByName>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetDeputyPracticesByNameSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof getDeputyPracticesByName>>
>;
export type GetDeputyPracticesByNameSuspenseQueryError = unknown;

export function useGetDeputyPracticesByNameSuspense<
  TData = Awaited<ReturnType<typeof getDeputyPracticesByName>>,
  TError = unknown,
>(
  params: GetDeputyPracticesByNameParams,
  options: {
    query: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getDeputyPracticesByName>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetDeputyPracticesByNameSuspense<
  TData = Awaited<ReturnType<typeof getDeputyPracticesByName>>,
  TError = unknown,
>(
  params: GetDeputyPracticesByNameParams,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getDeputyPracticesByName>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetDeputyPracticesByNameSuspense<
  TData = Awaited<ReturnType<typeof getDeputyPracticesByName>>,
  TError = unknown,
>(
  params: GetDeputyPracticesByNameParams,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getDeputyPracticesByName>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useGetDeputyPracticesByNameSuspense<
  TData = Awaited<ReturnType<typeof getDeputyPracticesByName>>,
  TError = unknown,
>(
  params: GetDeputyPracticesByNameParams,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getDeputyPracticesByName>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetDeputyPracticesByNameSuspenseQueryOptions(params, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getActiveDeputiesForDeputyPractice = (
  params: GetActiveDeputiesForDeputyPracticeParams,
  options?: SecondParameter<typeof request>,
  signal?: AbortSignal,
) => {
  return request<DeputyDto[]>({ url: `/deputies/active`, method: 'GET', params, signal }, options);
};

export const getGetActiveDeputiesForDeputyPracticeQueryKey = (params: GetActiveDeputiesForDeputyPracticeParams) => {
  return [`/deputies/active`, ...(params ? [params] : [])] as const;
};

export const getGetActiveDeputiesForDeputyPracticeQueryOptions = <
  TData = Awaited<ReturnType<typeof getActiveDeputiesForDeputyPractice>>,
  TError = unknown,
>(
  params: GetActiveDeputiesForDeputyPracticeParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getActiveDeputiesForDeputyPractice>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetActiveDeputiesForDeputyPracticeQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getActiveDeputiesForDeputyPractice>>> = ({ signal }) =>
    getActiveDeputiesForDeputyPractice(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getActiveDeputiesForDeputyPractice>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetActiveDeputiesForDeputyPracticeQueryResult = NonNullable<
  Awaited<ReturnType<typeof getActiveDeputiesForDeputyPractice>>
>;
export type GetActiveDeputiesForDeputyPracticeQueryError = unknown;

export function useGetActiveDeputiesForDeputyPractice<
  TData = Awaited<ReturnType<typeof getActiveDeputiesForDeputyPractice>>,
  TError = unknown,
>(
  params: GetActiveDeputiesForDeputyPracticeParams,
  options: {
    query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getActiveDeputiesForDeputyPractice>>, TError, TData>> &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getActiveDeputiesForDeputyPractice>>,
          TError,
          Awaited<ReturnType<typeof getActiveDeputiesForDeputyPractice>>
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof request>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetActiveDeputiesForDeputyPractice<
  TData = Awaited<ReturnType<typeof getActiveDeputiesForDeputyPractice>>,
  TError = unknown,
>(
  params: GetActiveDeputiesForDeputyPracticeParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getActiveDeputiesForDeputyPractice>>, TError, TData>> &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getActiveDeputiesForDeputyPractice>>,
          TError,
          Awaited<ReturnType<typeof getActiveDeputiesForDeputyPractice>>
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof request>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetActiveDeputiesForDeputyPractice<
  TData = Awaited<ReturnType<typeof getActiveDeputiesForDeputyPractice>>,
  TError = unknown,
>(
  params: GetActiveDeputiesForDeputyPracticeParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getActiveDeputiesForDeputyPractice>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useGetActiveDeputiesForDeputyPractice<
  TData = Awaited<ReturnType<typeof getActiveDeputiesForDeputyPractice>>,
  TError = unknown,
>(
  params: GetActiveDeputiesForDeputyPracticeParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getActiveDeputiesForDeputyPractice>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetActiveDeputiesForDeputyPracticeQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getGetActiveDeputiesForDeputyPracticeSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getActiveDeputiesForDeputyPractice>>,
  TError = unknown,
>(
  params: GetActiveDeputiesForDeputyPracticeParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof getActiveDeputiesForDeputyPractice>>, TError, TData>
    >;
    request?: SecondParameter<typeof request>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetActiveDeputiesForDeputyPracticeQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getActiveDeputiesForDeputyPractice>>> = ({ signal }) =>
    getActiveDeputiesForDeputyPractice(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getActiveDeputiesForDeputyPractice>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetActiveDeputiesForDeputyPracticeSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof getActiveDeputiesForDeputyPractice>>
>;
export type GetActiveDeputiesForDeputyPracticeSuspenseQueryError = unknown;

export function useGetActiveDeputiesForDeputyPracticeSuspense<
  TData = Awaited<ReturnType<typeof getActiveDeputiesForDeputyPractice>>,
  TError = unknown,
>(
  params: GetActiveDeputiesForDeputyPracticeParams,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof getActiveDeputiesForDeputyPractice>>, TError, TData>
    >;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetActiveDeputiesForDeputyPracticeSuspense<
  TData = Awaited<ReturnType<typeof getActiveDeputiesForDeputyPractice>>,
  TError = unknown,
>(
  params: GetActiveDeputiesForDeputyPracticeParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof getActiveDeputiesForDeputyPractice>>, TError, TData>
    >;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetActiveDeputiesForDeputyPracticeSuspense<
  TData = Awaited<ReturnType<typeof getActiveDeputiesForDeputyPractice>>,
  TError = unknown,
>(
  params: GetActiveDeputiesForDeputyPracticeParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof getActiveDeputiesForDeputyPractice>>, TError, TData>
    >;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useGetActiveDeputiesForDeputyPracticeSuspense<
  TData = Awaited<ReturnType<typeof getActiveDeputiesForDeputyPractice>>,
  TError = unknown,
>(
  params: GetActiveDeputiesForDeputyPracticeParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<Awaited<ReturnType<typeof getActiveDeputiesForDeputyPractice>>, TError, TData>
    >;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetActiveDeputiesForDeputyPracticeSuspenseQueryOptions(params, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getPatientDetails1 = (
  patientId: string,
  options?: SecondParameter<typeof request>,
  signal?: AbortSignal,
) => {
  return request<HeurekaPatientDetailsDto>(
    { url: `/callcenter/patients/${patientId}`, method: 'GET', signal },
    options,
  );
};

export const getGetPatientDetails1QueryKey = (patientId: string) => {
  return [`/callcenter/patients/${patientId}`] as const;
};

export const getGetPatientDetails1QueryOptions = <
  TData = Awaited<ReturnType<typeof getPatientDetails1>>,
  TError = unknown,
>(
  patientId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPatientDetails1>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetPatientDetails1QueryKey(patientId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPatientDetails1>>> = ({ signal }) =>
    getPatientDetails1(patientId, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!patientId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getPatientDetails1>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetPatientDetails1QueryResult = NonNullable<Awaited<ReturnType<typeof getPatientDetails1>>>;
export type GetPatientDetails1QueryError = unknown;

export function useGetPatientDetails1<TData = Awaited<ReturnType<typeof getPatientDetails1>>, TError = unknown>(
  patientId: string,
  options: {
    query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPatientDetails1>>, TError, TData>> &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getPatientDetails1>>,
          TError,
          Awaited<ReturnType<typeof getPatientDetails1>>
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof request>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetPatientDetails1<TData = Awaited<ReturnType<typeof getPatientDetails1>>, TError = unknown>(
  patientId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPatientDetails1>>, TError, TData>> &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getPatientDetails1>>,
          TError,
          Awaited<ReturnType<typeof getPatientDetails1>>
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof request>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetPatientDetails1<TData = Awaited<ReturnType<typeof getPatientDetails1>>, TError = unknown>(
  patientId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPatientDetails1>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useGetPatientDetails1<TData = Awaited<ReturnType<typeof getPatientDetails1>>, TError = unknown>(
  patientId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPatientDetails1>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetPatientDetails1QueryOptions(patientId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getGetPatientDetails1SuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getPatientDetails1>>,
  TError = unknown,
>(
  patientId: string,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getPatientDetails1>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetPatientDetails1QueryKey(patientId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPatientDetails1>>> = ({ signal }) =>
    getPatientDetails1(patientId, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getPatientDetails1>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetPatientDetails1SuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getPatientDetails1>>>;
export type GetPatientDetails1SuspenseQueryError = unknown;

export function useGetPatientDetails1Suspense<TData = Awaited<ReturnType<typeof getPatientDetails1>>, TError = unknown>(
  patientId: string,
  options: {
    query: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getPatientDetails1>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetPatientDetails1Suspense<TData = Awaited<ReturnType<typeof getPatientDetails1>>, TError = unknown>(
  patientId: string,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getPatientDetails1>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetPatientDetails1Suspense<TData = Awaited<ReturnType<typeof getPatientDetails1>>, TError = unknown>(
  patientId: string,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getPatientDetails1>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useGetPatientDetails1Suspense<TData = Awaited<ReturnType<typeof getPatientDetails1>>, TError = unknown>(
  patientId: string,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getPatientDetails1>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetPatientDetails1SuspenseQueryOptions(patientId, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const isPatientDataAvailable = (
  params: IsPatientDataAvailableParams,
  options?: SecondParameter<typeof request>,
  signal?: AbortSignal,
) => {
  return request<boolean>({ url: `/callcenter/patient/exists`, method: 'GET', params, signal }, options);
};

export const getIsPatientDataAvailableQueryKey = (params: IsPatientDataAvailableParams) => {
  return [`/callcenter/patient/exists`, ...(params ? [params] : [])] as const;
};

export const getIsPatientDataAvailableQueryOptions = <
  TData = Awaited<ReturnType<typeof isPatientDataAvailable>>,
  TError = unknown,
>(
  params: IsPatientDataAvailableParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof isPatientDataAvailable>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getIsPatientDataAvailableQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof isPatientDataAvailable>>> = ({ signal }) =>
    isPatientDataAvailable(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof isPatientDataAvailable>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type IsPatientDataAvailableQueryResult = NonNullable<Awaited<ReturnType<typeof isPatientDataAvailable>>>;
export type IsPatientDataAvailableQueryError = unknown;

export function useIsPatientDataAvailable<TData = Awaited<ReturnType<typeof isPatientDataAvailable>>, TError = unknown>(
  params: IsPatientDataAvailableParams,
  options: {
    query: Partial<UseQueryOptions<Awaited<ReturnType<typeof isPatientDataAvailable>>, TError, TData>> &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof isPatientDataAvailable>>,
          TError,
          Awaited<ReturnType<typeof isPatientDataAvailable>>
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof request>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useIsPatientDataAvailable<TData = Awaited<ReturnType<typeof isPatientDataAvailable>>, TError = unknown>(
  params: IsPatientDataAvailableParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof isPatientDataAvailable>>, TError, TData>> &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof isPatientDataAvailable>>,
          TError,
          Awaited<ReturnType<typeof isPatientDataAvailable>>
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof request>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useIsPatientDataAvailable<TData = Awaited<ReturnType<typeof isPatientDataAvailable>>, TError = unknown>(
  params: IsPatientDataAvailableParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof isPatientDataAvailable>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useIsPatientDataAvailable<TData = Awaited<ReturnType<typeof isPatientDataAvailable>>, TError = unknown>(
  params: IsPatientDataAvailableParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof isPatientDataAvailable>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getIsPatientDataAvailableQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getIsPatientDataAvailableSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof isPatientDataAvailable>>,
  TError = unknown,
>(
  params: IsPatientDataAvailableParams,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof isPatientDataAvailable>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getIsPatientDataAvailableQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof isPatientDataAvailable>>> = ({ signal }) =>
    isPatientDataAvailable(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof isPatientDataAvailable>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type IsPatientDataAvailableSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof isPatientDataAvailable>>>;
export type IsPatientDataAvailableSuspenseQueryError = unknown;

export function useIsPatientDataAvailableSuspense<
  TData = Awaited<ReturnType<typeof isPatientDataAvailable>>,
  TError = unknown,
>(
  params: IsPatientDataAvailableParams,
  options: {
    query: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof isPatientDataAvailable>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useIsPatientDataAvailableSuspense<
  TData = Awaited<ReturnType<typeof isPatientDataAvailable>>,
  TError = unknown,
>(
  params: IsPatientDataAvailableParams,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof isPatientDataAvailable>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useIsPatientDataAvailableSuspense<
  TData = Awaited<ReturnType<typeof isPatientDataAvailable>>,
  TError = unknown,
>(
  params: IsPatientDataAvailableParams,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof isPatientDataAvailable>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useIsPatientDataAvailableSuspense<
  TData = Awaited<ReturnType<typeof isPatientDataAvailable>>,
  TError = unknown,
>(
  params: IsPatientDataAvailableParams,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof isPatientDataAvailable>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getIsPatientDataAvailableSuspenseQueryOptions(params, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getPartnerPractices = (
  params?: GetPartnerPracticesParams,
  options?: SecondParameter<typeof request>,
  signal?: AbortSignal,
) => {
  return request<PracticeDto[]>({ url: `/callcenter/partnerpractices`, method: 'GET', params, signal }, options);
};

export const getGetPartnerPracticesQueryKey = (params?: GetPartnerPracticesParams) => {
  return [`/callcenter/partnerpractices`, ...(params ? [params] : [])] as const;
};

export const getGetPartnerPracticesQueryOptions = <
  TData = Awaited<ReturnType<typeof getPartnerPractices>>,
  TError = unknown,
>(
  params?: GetPartnerPracticesParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPartnerPractices>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetPartnerPracticesQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPartnerPractices>>> = ({ signal }) =>
    getPartnerPractices(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getPartnerPractices>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetPartnerPracticesQueryResult = NonNullable<Awaited<ReturnType<typeof getPartnerPractices>>>;
export type GetPartnerPracticesQueryError = unknown;

export function useGetPartnerPractices<TData = Awaited<ReturnType<typeof getPartnerPractices>>, TError = unknown>(
  params: undefined | GetPartnerPracticesParams,
  options: {
    query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPartnerPractices>>, TError, TData>> &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getPartnerPractices>>,
          TError,
          Awaited<ReturnType<typeof getPartnerPractices>>
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof request>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetPartnerPractices<TData = Awaited<ReturnType<typeof getPartnerPractices>>, TError = unknown>(
  params?: GetPartnerPracticesParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPartnerPractices>>, TError, TData>> &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getPartnerPractices>>,
          TError,
          Awaited<ReturnType<typeof getPartnerPractices>>
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof request>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetPartnerPractices<TData = Awaited<ReturnType<typeof getPartnerPractices>>, TError = unknown>(
  params?: GetPartnerPracticesParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPartnerPractices>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useGetPartnerPractices<TData = Awaited<ReturnType<typeof getPartnerPractices>>, TError = unknown>(
  params?: GetPartnerPracticesParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPartnerPractices>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetPartnerPracticesQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getGetPartnerPracticesSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getPartnerPractices>>,
  TError = unknown,
>(
  params?: GetPartnerPracticesParams,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getPartnerPractices>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetPartnerPracticesQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPartnerPractices>>> = ({ signal }) =>
    getPartnerPractices(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getPartnerPractices>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetPartnerPracticesSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getPartnerPractices>>>;
export type GetPartnerPracticesSuspenseQueryError = unknown;

export function useGetPartnerPracticesSuspense<
  TData = Awaited<ReturnType<typeof getPartnerPractices>>,
  TError = unknown,
>(
  params: undefined | GetPartnerPracticesParams,
  options: {
    query: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getPartnerPractices>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetPartnerPracticesSuspense<
  TData = Awaited<ReturnType<typeof getPartnerPractices>>,
  TError = unknown,
>(
  params?: GetPartnerPracticesParams,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getPartnerPractices>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetPartnerPracticesSuspense<
  TData = Awaited<ReturnType<typeof getPartnerPractices>>,
  TError = unknown,
>(
  params?: GetPartnerPracticesParams,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getPartnerPractices>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useGetPartnerPracticesSuspense<
  TData = Awaited<ReturnType<typeof getPartnerPractices>>,
  TError = unknown,
>(
  params?: GetPartnerPracticesParams,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getPartnerPractices>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetPartnerPracticesSuspenseQueryOptions(params, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getEmailAddress = (
  params: GetEmailAddressParams,
  options?: SecondParameter<typeof request>,
  signal?: AbortSignal,
) => {
  return request<string>({ url: `/callcenter/email`, method: 'GET', params, signal }, options);
};

export const getGetEmailAddressQueryKey = (params: GetEmailAddressParams) => {
  return [`/callcenter/email`, ...(params ? [params] : [])] as const;
};

export const getGetEmailAddressQueryOptions = <TData = Awaited<ReturnType<typeof getEmailAddress>>, TError = unknown>(
  params: GetEmailAddressParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getEmailAddress>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetEmailAddressQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getEmailAddress>>> = ({ signal }) =>
    getEmailAddress(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getEmailAddress>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetEmailAddressQueryResult = NonNullable<Awaited<ReturnType<typeof getEmailAddress>>>;
export type GetEmailAddressQueryError = unknown;

export function useGetEmailAddress<TData = Awaited<ReturnType<typeof getEmailAddress>>, TError = unknown>(
  params: GetEmailAddressParams,
  options: {
    query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getEmailAddress>>, TError, TData>> &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getEmailAddress>>,
          TError,
          Awaited<ReturnType<typeof getEmailAddress>>
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof request>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetEmailAddress<TData = Awaited<ReturnType<typeof getEmailAddress>>, TError = unknown>(
  params: GetEmailAddressParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getEmailAddress>>, TError, TData>> &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getEmailAddress>>,
          TError,
          Awaited<ReturnType<typeof getEmailAddress>>
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof request>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetEmailAddress<TData = Awaited<ReturnType<typeof getEmailAddress>>, TError = unknown>(
  params: GetEmailAddressParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getEmailAddress>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useGetEmailAddress<TData = Awaited<ReturnType<typeof getEmailAddress>>, TError = unknown>(
  params: GetEmailAddressParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getEmailAddress>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetEmailAddressQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getGetEmailAddressSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getEmailAddress>>,
  TError = unknown,
>(
  params: GetEmailAddressParams,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getEmailAddress>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetEmailAddressQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getEmailAddress>>> = ({ signal }) =>
    getEmailAddress(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getEmailAddress>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetEmailAddressSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getEmailAddress>>>;
export type GetEmailAddressSuspenseQueryError = unknown;

export function useGetEmailAddressSuspense<TData = Awaited<ReturnType<typeof getEmailAddress>>, TError = unknown>(
  params: GetEmailAddressParams,
  options: {
    query: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getEmailAddress>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetEmailAddressSuspense<TData = Awaited<ReturnType<typeof getEmailAddress>>, TError = unknown>(
  params: GetEmailAddressParams,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getEmailAddress>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetEmailAddressSuspense<TData = Awaited<ReturnType<typeof getEmailAddress>>, TError = unknown>(
  params: GetEmailAddressParams,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getEmailAddress>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useGetEmailAddressSuspense<TData = Awaited<ReturnType<typeof getEmailAddress>>, TError = unknown>(
  params: GetEmailAddressParams,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getEmailAddress>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetEmailAddressSuspenseQueryOptions(params, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getAccessLogsForPractice = (
  params: GetAccessLogsForPracticeParams,
  options?: SecondParameter<typeof request>,
  signal?: AbortSignal,
) => {
  return request<AccessLogDto[]>({ url: `/accesslogs`, method: 'GET', params, signal }, options);
};

export const getGetAccessLogsForPracticeQueryKey = (params: GetAccessLogsForPracticeParams) => {
  return [`/accesslogs`, ...(params ? [params] : [])] as const;
};

export const getGetAccessLogsForPracticeQueryOptions = <
  TData = Awaited<ReturnType<typeof getAccessLogsForPractice>>,
  TError = unknown,
>(
  params: GetAccessLogsForPracticeParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAccessLogsForPractice>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAccessLogsForPracticeQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAccessLogsForPractice>>> = ({ signal }) =>
    getAccessLogsForPractice(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getAccessLogsForPractice>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetAccessLogsForPracticeQueryResult = NonNullable<Awaited<ReturnType<typeof getAccessLogsForPractice>>>;
export type GetAccessLogsForPracticeQueryError = unknown;

export function useGetAccessLogsForPractice<
  TData = Awaited<ReturnType<typeof getAccessLogsForPractice>>,
  TError = unknown,
>(
  params: GetAccessLogsForPracticeParams,
  options: {
    query: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAccessLogsForPractice>>, TError, TData>> &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getAccessLogsForPractice>>,
          TError,
          Awaited<ReturnType<typeof getAccessLogsForPractice>>
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof request>;
  },
): DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetAccessLogsForPractice<
  TData = Awaited<ReturnType<typeof getAccessLogsForPractice>>,
  TError = unknown,
>(
  params: GetAccessLogsForPracticeParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAccessLogsForPractice>>, TError, TData>> &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getAccessLogsForPractice>>,
          TError,
          Awaited<ReturnType<typeof getAccessLogsForPractice>>
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof request>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetAccessLogsForPractice<
  TData = Awaited<ReturnType<typeof getAccessLogsForPractice>>,
  TError = unknown,
>(
  params: GetAccessLogsForPracticeParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAccessLogsForPractice>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useGetAccessLogsForPractice<
  TData = Awaited<ReturnType<typeof getAccessLogsForPractice>>,
  TError = unknown,
>(
  params: GetAccessLogsForPracticeParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getAccessLogsForPractice>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetAccessLogsForPracticeQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getGetAccessLogsForPracticeSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof getAccessLogsForPractice>>,
  TError = unknown,
>(
  params: GetAccessLogsForPracticeParams,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getAccessLogsForPractice>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAccessLogsForPracticeQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAccessLogsForPractice>>> = ({ signal }) =>
    getAccessLogsForPractice(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof getAccessLogsForPractice>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type GetAccessLogsForPracticeSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof getAccessLogsForPractice>>
>;
export type GetAccessLogsForPracticeSuspenseQueryError = unknown;

export function useGetAccessLogsForPracticeSuspense<
  TData = Awaited<ReturnType<typeof getAccessLogsForPractice>>,
  TError = unknown,
>(
  params: GetAccessLogsForPracticeParams,
  options: {
    query: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getAccessLogsForPractice>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetAccessLogsForPracticeSuspense<
  TData = Awaited<ReturnType<typeof getAccessLogsForPractice>>,
  TError = unknown,
>(
  params: GetAccessLogsForPracticeParams,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getAccessLogsForPractice>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useGetAccessLogsForPracticeSuspense<
  TData = Awaited<ReturnType<typeof getAccessLogsForPractice>>,
  TError = unknown,
>(
  params: GetAccessLogsForPracticeParams,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getAccessLogsForPractice>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useGetAccessLogsForPracticeSuspense<
  TData = Awaited<ReturnType<typeof getAccessLogsForPractice>>,
  TError = unknown,
>(
  params: GetAccessLogsForPracticeParams,
  options?: {
    query?: Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getAccessLogsForPractice>>, TError, TData>>;
    request?: SecondParameter<typeof request>;
  },
): UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getGetAccessLogsForPracticeSuspenseQueryOptions(params, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

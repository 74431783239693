import { Box } from '@healthinal/ui';
import { SmcSpinner } from './SmcSpinner';

export function SplashScreen() {
  return (
    <Box display="flex" alignItems="center" justifyContent="center" flex={1} height="80vh">
      <SmcSpinner />
    </Box>
  );
}

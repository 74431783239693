import { Alert, Card, IconButton, Icons, Stack, styled, Typography } from '@healthinal/ui';
import { useNavigate } from 'react-router-dom';
import { theme } from '../../theme';
import { ErrorPresentation } from './errors';

type ErrorCardProps = ErrorPresentation & {
  eventId?: string;
  isRoot?: boolean;
};

export function ErrorCard({ title, description, details, actions, shouldBeReported, eventId, isRoot }: ErrorCardProps) {
  const navigate = useNavigate();
  return (
    <Stack alignItems="center" justifyContent="center" flex={1} gap={5} sx={{ height: isRoot ? '100vh' : '100%' }}>
      <StyledCard variant="plain">
        <TopLeftIconButton size="sm" onClick={() => navigate(-1)}>
          <Icons.ArrowBack />
        </TopLeftIconButton>
        <Icons.ErrorOutline size="lg" color="danger" />
        <Typography level="h3">{title}</Typography>
        <Typography level="body-md">{description}</Typography>
        {details && <Details>{details}</Details>}
        {actions}
      </StyledCard>
      {shouldBeReported && <Typography level="body-sm">Sentry ID: {eventId}</Typography>}
    </Stack>
  );
}

const StyledCard = styled(Card)`
  padding: 48px;
  min-width: 360px;
  max-width: 560px;
  gap: 24px;
  box-shadow: ${theme.vars.shadow.xl};
  align-items: center;
`;

const TopLeftIconButton = styled(IconButton)`
  position: absolute;
  top: 16px;
  left: 16px;
`;

const Details = styled(Alert)`
  font-family: monospace;
  white-space: pre-line;
`;

import { getCurrentScope, init, makeBrowserOfflineTransport, makeFetchTransport } from '@sentry/react';
import axios from 'axios';
import { v4 as uuidV4 } from 'uuid';
import { interpretError } from './components/error-handling/errors';
import { environment } from './utils/environment';

const key = 'sentry-user-id';

const sentrySessionId = uuidV4();

const sentryUserId =
  localStorage?.getItem(key) ??
  (() => {
    const id = uuidV4();
    localStorage?.setItem(key, id);
    return id;
  })();

const sentryRequestHeaders = {
  'sentry-custom-session-id': sentrySessionId,
  'sentry-custom-user-id': sentryUserId,
};

const instrumentAxios = () => {
  Object.entries(sentryRequestHeaders).forEach(([header, value]) => (axios.defaults.headers.common[header] = value));
};

export const setupSentry = () => {
  init({
    dsn: environment === 'localhost' ? '' : `${import.meta.env.VITE_SENTRY_DSN}`,
    transport: makeBrowserOfflineTransport(makeFetchTransport),
    tracesSampleRate: 0.1,
    autoSessionTracking: false,
    environment,
    release: `${import.meta.env.VITE_APP_COMMIT_ID}`,
    normalizeDepth: 10,
    beforeSend: (event, hint) => {
      const { shouldBeReported } = interpretError(hint.originalException);
      return shouldBeReported ? event : null;
    },
  });
  instrumentAxios();
  getCurrentScope().setTag('session_id', sentrySessionId).setTag('user_id', sentryUserId);
};

import { captureException } from '@sentry/react';
import { MutationCache, QueryClient } from '@tanstack/react-query';
import { AxiosError, HttpStatusCode, isAxiosError } from 'axios';
import { interpretError } from '../components/error-handling/errors';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      throwOnError: true,
      retry: (failureCount, error) => {
        if (isAxiosError(error)) {
          const statusCode = error.response?.status;
          if (
            error.code === AxiosError.ERR_NETWORK ||
            statusCode === HttpStatusCode.GatewayTimeout ||
            statusCode === HttpStatusCode.ServiceUnavailable ||
            statusCode === HttpStatusCode.BadGateway
          ) {
            return failureCount < 3;
          }
        }
        return false;
      },
    },
    mutations: {
      throwOnError: (error) => {
        const { shouldBeHandledAtRoot } = interpretError(error);
        return !!shouldBeHandledAtRoot;
      },
    },
  },
  mutationCache: new MutationCache({
    onError: (error) => captureException(error),
  }),
});
